<template>
  <div class="experience-products-item-image">
    <div class="experience-products-item-shadow"></div>
    <img v-if="image" :src="image" alt="product">
    <img v-else src="@/assets/empty.png" alt="product">
    <div class="experience-products-item-container">
      <span class="experience-products-item-name">{{ name }}</span>
      <div class="experience-products-item-separator"></div>
      <span class="experience-products-item-price"><b>Da € {{ price }}</b> <span class="experience-products-item-price-info">a persona</span></span>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';

export default {
  props: {
    merch: Object,
  },
  setup(props) {
    const name = ref('');
    const price = ref(0);
    //  const discountedPrice = ref(null);
    const image = ref('');

    //  const idDiscounted = computed(() => discountedPrice.value !== null);

    onMounted(() => {
      name.value = decodeURIComponent(props?.merch?.locs[0]?.name) ?? '';
      price.value = props?.merch?.price ?? 0.00;
      //  discountedPrice.value = props?.merch?.discountedPrice?.toFixed(2) ?? null;
      //  image.value = props?.merch?.images[0]?.base64 ?? null;
      image.value = props?.merch?.image ?? null;
      if (image.value != null) {
        image.value = process.env.VUE_APP_IMAGE_URL + image.value;
      }
    });

    return {
      name,
      price,
      //  discountedPrice,
      image,
      //  idDiscounted,
    };
  },
};
</script>
