
import MerchItem from '@/components/MerchItem.vue';
import {
  computed, onMounted, onUnmounted, ref, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/core/api';
import { piniaCart, piniaUser } from '@/pinia';
import swalAlert from '@/alert';
import { setCarousel, destroyCarousel } from '@/core/carousel/';

export default {
  components: {
    MerchItem,
  },
  setup() {
    const pinia = piniaCart();
    const piniaUserObject = piniaUser();
    const { dangerAlert } = swalAlert();
    const { post } = api();
    const route = useRoute();
    const router = useRouter();
    const product = ref();
    const code = ref('');
    const name = ref('');
    const description = ref('');
    const price = ref(0);
    const discountedPrice = ref(null);
    const qta = ref(0);
    const weight = ref(0);
    const sizeGuide = ref(0);
    const preorder = ref(0);
    const images = ref([{ name: '' }]);
    const counter = ref(1);
    const sizes = ref([{
      id: '',
      value: '',
      id_variant: '',
    }]);
    const colors = ref([{
      id: '',
      value: '',
      id_variant: '',
    }]);
    const materials = ref([{
      id: '',
      value: '',
      id_variant: '',
    }]);
    const products = ref([]);
    const idSize = ref(0);
    const idColor = ref(0);
    const idMaterial = ref(0);

    const idDiscounted = computed(() => discountedPrice.value !== null);

    function less() {
      if (counter.value > 1) {
        counter.value -= 1;
      }
    }

    function plus() {
      if (counter.value < qta.value) {
        counter.value += 1;
      }
    }

    function valueOption(option: number) {
      switch (option) {
        case 1:
          return (idColor.value > 0) ? (colors.value.find((c) => c.id === idColor.value.toString())!.value) : '';
        case 2:
          return (idSize.value > 0) ? (sizes.value.find((s) => s.id === idSize.value.toString())!.value) : '';
        case 3:
          return (idMaterial.value > 0) ? (materials.value.find((m) => m.id === idMaterial.value.toString())!.value) : '';
        default:
          return '';
      }
    }

    function getSrc(index: number) {
      return process.env.VUE_APP_IMAGE_URL + images.value[index].name;
    }

    function init() {
      destroyCarousel('#carouselpc-product');
      counter.value = 1;
      code.value = route?.params?.id?.toString() ?? '';
      if (code.value !== '') {
        const data = {
          code: code.value,
          color: valueOption(1),
          size: valueOption(2),
          material: valueOption(3),
        };
        post('getProduct', data)
          .then((res) => {
            product.value = res?.data ?? null;
            name.value = decodeURIComponent(res?.data?.locs[0]?.name) ?? '';
            description.value = decodeURIComponent(res?.data?.locs[0]?.description) ?? '';
            price.value = res?.data?.price?.toFixed(2) ?? 0.00;
            discountedPrice.value = res?.data?.discountedPrice?.toFixed(2) ?? null;
            qta.value = parseInt(res?.data?.qta ?? 0, 10);
            weight.value = parseInt(res?.data?.weight ?? 0, 10);
            sizeGuide.value = parseInt(res?.data?.sizeGuide ?? 0, 10);
            preorder.value = parseInt(res?.data?.preorder ?? 0, 10);
            images.value = res.data?.images ?? [];
            sizes.value = res.data?.sizes ?? [];
            colors.value = res.data?.colors ?? [];
            materials.value = res.data?.materials ?? [];
            idSize.value = res.data?.id_size ?? 0;
            idColor.value = res.data?.id_color ?? 0;
            idMaterial.value = res.data?.id_material ?? 0;
            if (images.value.length > 0) {
              setTimeout(() => {
                setCarousel('#carouselpc-product');
              }, 1000);
            }
            post('getRandomProducts', data)
              .then((resRandom) => {
                products.value = resRandom?.data ?? [];
              });
          });
      }
    }

    function addToCart(toCart = false) {
      const data = {
        id: product.value.id,
        id_product: product.value.id_product,
        qta: counter.value,
        jwt: piniaUserObject.getJwt,
      };
      post('setInstanceCart', data)
        .then((res) => {
          const status = res?.data?.status ?? 0;
          if (status === 1) {
            const qtaToAdd = res?.data?.qta ?? 0;
            pinia.addCounter(qtaToAdd);
            const logged = res?.data?.logged ?? false;
            if (!logged) {
              piniaUserObject.logout();
            }
            init();
            if (toCart === true) {
              router.push({ path: '/cart' });
            }
          } else {
            dangerAlert('Il prodotto non è più disponibile per la quantità scelta')
              .then(() => {
                window.location.reload();
              });
          }
        });
    }

    function goToCart() {
      if (counter.value > 0) {
        addToCart(true);
      } else {
        router.push({ path: '/cart' });
      }
    }

    function goToCheckout() {
      router.push({ path: '/cart' });
    }

    function selectedOption(option: number, value: number) {
      switch (option) {
        case 1:
          return (idColor.value === value) ? 'selected' : '';
        case 2:
          return (idSize.value === value) ? 'selected' : '';
        case 3:
          return (idMaterial.value === value) ? 'selected' : '';
        default:
          return '';
      }
    }

    function changeOption(option: number, value: number) {
      switch (option) {
        case 1:
          idColor.value = value;
          break;
        case 2:
          idSize.value = value;
          break;
        case 3:
          idMaterial.value = value;
          break;
        default:
          break;
      }
      init();
    }

    onMounted(() => {
      init();
    });

    onUnmounted(() => {
      destroyCarousel('#carouselpc-product');
    });

    watch(route, () => {
      idSize.value = 0;
      idColor.value = 0;
      idMaterial.value = 0;
      init();
    });

    return {
      product,
      name,
      description,
      price,
      discountedPrice,
      qta,
      weight,
      sizeGuide,
      preorder,
      images,
      counter,
      less,
      plus,
      addToCart,
      goToCart,
      goToCheckout,
      products,
      sizes,
      colors,
      materials,
      selectedOption,
      changeOption,
      idDiscounted,
      getSrc,
    };
  },
};
