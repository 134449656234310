<template>
  <HeaderView/>
  <main class="bg-white">
    <router-view/>
  </main>
  <FooterView/>
  <ModalRememberOne/>
  <ModalRememberTwo/>
  <ModalGuideSize/>
</template>

<script>
import HeaderView from '@/template/header/HeaderView.vue';
import FooterView from '@/template/footer/FooterView.vue';
import ModalRememberOne from '@/components/modals/ModalRememberOne.vue';
import ModalRememberTwo from '@/components/modals/ModalRememberTwo.vue';
import ModalGuideSize from '@/components/modals/ModalGuideSize.vue';
import { showModal } from '@/core/modal';
import { onMounted, watch } from 'vue';
import { piniaCart } from '@/pinia';

export default {
  components: {
    HeaderView,
    FooterView,
    ModalRememberOne,
    ModalRememberTwo,
    ModalGuideSize,
  },
  setup() {
    const pinia = piniaCart();

    onMounted(() => {
      setTimeout(() => {
        if (pinia.getCounter === 0) {
          showModal('kt_modal_remember_one');
        }
      }, 90000);
    });

    watch(() => pinia.getCounter, (value, oldValue) => {
      if (value === 0) {
        setTimeout(() => {
          showModal('kt_modal_remember_one');
        }, 90000);
      }
      /*
      else if (oldValue === 0 && value > 0) {
        setTimeout(() => {
          showModal('kt_modal_remember_two');
        }, 60000);
      }
      */
    });
  },
};
</script>
