
import { hideModal } from '@/core/modal';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const modalRememberOne = ref<null | HTMLElement>(null);
    const router = useRouter();

    function close() {
      hideModal(modalRememberOne.value);
      router.push({ path: '/merch' });
    }

    return {
      modalRememberOne,
      close,
    };
  },
};
