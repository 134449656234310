import { createApp } from 'vue';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import i18n from '@/utils/i18n';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import 'bootstrap';

createApp(App)
  .use(VueLoading, {
    color: '#B82620',
    isFullPage: true,
  })
  .use(createPinia())
  .use(router)
  .use(i18n)
  .mount('#app');
