import axios from 'axios';
import { useLoading } from 'vue-loading-overlay';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: true,
  headers: {
    Authorization: process.env.VUE_APP_AUTHORIZATION,
  },
});

export default function api() {
  const $loading = useLoading();

  function get(name: string, loading = true) {
    let loader:any;
    if (loading) {
      loader = $loading.show();
    }
    return apiClient
      .get(name)
      .finally(() => {
        if (loading) {
          loader.hide();
        }
      });
  }

  function post(name: string, data = {}, loading = true) {
    let loader:any;
    if (loading) {
      loader = $loading.show();
    }
    return apiClient
      .post(name, data)
      .finally(() => {
        if (loading) {
          loader.hide();
        }
      });
  }

  return {
    get,
    post,
  };
}
