<template>
  <div class="cart-product-row-one">
    <div class="cart-product-row-one-image">
      <img v-if="image" :src="image" alt="product">
      <img v-else src="@/assets/empty.png" alt="product">
    </div>
    <div class="cart-product-row-one-info">
      <div class="cart-product-row-one-info-title">
        {{ name }}
      </div>
      <div class="cart-product-row-one-info-subtitle">
        {{ date }}
      </div>
      <div class="cart-product-row-one-info-subtitle">
        {{ time }}
      </div>
      <div class="cart-product-row-one-info-subtitle">
        {{ type }}
      </div>
    </div>
    <div v-if="canRemove" class="cart-product-row-one-remove">
      <button class="btn" @click="remove" @keydown="remove">Rimuovi</button>
    </div>
  </div>
  <div class="cart-product-row-two">
    <div class="cart-product-row-two-item">
      <span class="cart-product-row-two-item-title">Q.tà</span>
      <span class="cart-product-row-two-item-price">{{ qta }}</span>
    </div>
    <div class="cart-product-row-two-item">
      <span class="cart-product-row-two-item-title">Prezzo Unitario</span>
      <span class="cart-product-row-two-item-price">{{ price }}</span>
    </div>
    <div class="cart-product-row-two-item">
      <span class="cart-product-row-two-item-title">Subtotale</span>
      <span class="cart-product-row-two-item-price">{{ total }}</span>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { piniaCart } from '@/pinia';
import api from '@/core/api';

export default {
  props: {
    product: Object,
    canRemove: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { post } = api();
    const pinia = piniaCart();
    const name = ref('');
    const date = ref('');
    const time = ref('');
    const qta = ref(0);
    const price = ref(0);
    const total = ref(0);
    const image = ref('');
    const type = ref('');

    function remove() {
      const data = {
        id: props?.product?.id ?? 0,
      };
      post('removeExperienceCart', data)
        .then(() => {
          pinia.removedItem = true;
        });
    }

    onMounted(() => {
      name.value = props?.product?.name ?? '';
      date.value = props?.product?.date ?? '';
      time.value = props?.product?.time ?? '';
      qta.value = props?.product?.qta ?? '';
      price.value = props?.product?.price ?? '';
      total.value = props?.product?.total ?? '';
      image.value = props?.product?.image ?? null;
      if (image.value != null) {
        image.value = process.env.VUE_APP_IMAGE_URL + image.value;
      }
      type.value = props?.product?.type ?? '';
    });

    return {
      name,
      date,
      time,
      qta,
      price,
      total,
      image,
      type,
      remove,
    };
  },
};
</script>
<style lang="scss" scoped>
  .cart-product-row-one-info {
    display: flex;
    flex-direction: column;
    padding: 15px;
    .cart-product-row-one-info-title {
      font-family: Poppins;
      font-size: 20px;
      font-weight: bold;
      color: #6E6E6E;
    }
    .cart-product-row-one-info-subtitle {
      font-family: Poppins;
      font-size: 14px;
      color: #6E6E6E;
    }
  }
</style>
