
import { hideModal } from '@/core/modal';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const modalRememberTwo = ref<null | HTMLElement>(null);
    const router = useRouter();

    function close() {
      hideModal(modalRememberTwo.value);
      router.push({ path: '/merch' });
    }

    return {
      modalRememberTwo,
      close,
    };
  },
};
