import Swal from 'sweetalert2';
//  import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

export default function swalAlert() {
  const router = useRouter();
  //  const t = useI18n();

  function operationConfirm(text: string, route = '') {
    return Swal.fire({
      text,
      icon: 'success',
      buttonsStyling: false,
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'btn fw-bold btn-light-primary',
      },
    }).then(() => {
      if (route !== '') {
        router.push({ name: route });
      }
    });
  }

  function dangerAlert(text: string) {
    return Swal.fire({
      text,
      icon: 'error',
      buttonsStyling: false,
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'btn fw-bold btn-light-primary',
      },
    });
  }

  return {
    operationConfirm,
    dangerAlert,
  };
}
