<template>
  <div class="login-container">
    <span class="login-title">Login</span>
    <div class="login-subtitle">
      <span class="login-subtitle-question">Non riesci ad effettuare il login?</span>
      <span class="login-subtitle-answer" @click="toggle" @keydown="toggle">Registrati adesso</span>
    </div>
    <form @submit="submit">
      <div class="row">
        <div class="col-12 col-md-6">
          <label for="email" class="me-3"></label>
          <input type="email" id="email" name="email" class="form-control" placeholder="E-mail" aria-label="E-mail"
                 v-model="v.email.$model" :class="status(v.email)" autocomplete="off">
        </div>
        <div class="col-12 col-md-6">
          <label for="password" class="me-3"></label>
          <input type="password" id="password" name="password" class="form-control" placeholder="Password" aria-label="Password"
                 v-model="v.password.$model" :class="status(v.password)" autocomplete="off">
        </div>
      </div>
      <div class="login-subtitle my-4">
        <span class="login-subtitle-question">Hai dimenticato la password?</span>
        <span class="login-subtitle-answer" @click="resetPassword(v.email)" @keydown="resetPassword(v.email)">Clicca qui</span>
      </div>
      <div class="row">
        <div class="col-12 text-start">
          <button type="submit" class="btn btn-pc btn-pc-secondary">Conferma</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { piniaCart, piniaUser } from '@/pinia';
import { required, email } from '@vuelidate/validators';
import { computed, reactive } from 'vue';
import useVuelidate from '@vuelidate/core';
import api from '@/core/api';
import swalAlert from '@/alert';

export default {
  setup() {
    const pinia = piniaUser();
    const piniaCartobject = piniaCart();
    const { post } = api();
    const { operationConfirm, dangerAlert } = swalAlert();
    const state = reactive({
      email: '',
      password: '',
    });

    const rules = computed(() => ({
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    }));

    const v = useVuelidate(rules, state, { $scope: false });

    function status(validation) {
      return {
        'is-invalid': validation.$error,
      };
    }

    function submit(e) {
      e.preventDefault();
      v.value.$validate();

      //  console.log('$error', v.value.$errors);

      if (!v.value.$invalid) {
        const dataLogin = {
          email: state.email,
          password: state.password,
        };
        post('userlogin', dataLogin)
          .then((resLogin) => {
            const statu = resLogin?.data?.status ?? 0;
            if (statu === 1) {
              const jwt = resLogin?.data?.jwt ?? '';
              pinia.setJwt(jwt);
              operationConfirm('Login avvenuto con successo')
                .then(() => {
                  state.email = '';
                  state.password = '';
                  v.value.$reset();
                  piniaCartobject.selectedTab = 2;
                });
            } else {
              dangerAlert('Errore durante il login, verifica le credenziali inserite');
            }
          });
      }
    }

    function toggle() {
      pinia.setLogin(false);
    }

    function resetPassword(validation) {
      if (!validation.$error) {
        const dataReset = {
          email: state.email,
        };
        post('resetPassword', dataReset)
          .then((resLogin) => {
            const statu = resLogin?.data?.status ?? 0;
            if (statu === 1) {
              operationConfirm('Abbiamo inviato un\'email per il recupero della password');
            } else {
              dangerAlert('Si è verificato un\'errore, riprova!');
            }
          });
      } else {
        dangerAlert('Inserisci un\'email valida');
      }
    }

    return {
      toggle,
      state,
      rules,
      v,
      status,
      submit,
      resetPassword,
    };
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  .login-title {
    display: block;
    margin-bottom: 15px;
    font-family: Poppins;
    font-size: 30px;
    font-weight: bold;
    color: #B82620;
  }
  .login-subtitle {
    display: flex;
    gap: 15px;
    .login-subtitle-question {
      font-family: Poppins;
      font-size: 18px;
      font-weight: bold;
      color: #000;
    }
    .login-subtitle-answer {
      cursor: pointer;
      text-decoration: underline;
      font-family: Poppins;
      font-size: 18px;
      font-weight: bold;
      color: #429CE2;
    }
  }
  button {
    width: 100%;
    max-width: 150px;
  }
}
</style>
