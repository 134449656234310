import { defineStore } from 'pinia';

export const piniaCart = defineStore('piniaCart', {
  state: () => ({
    counter: 0,
    selectedTab: 0,
    timer: 0,
    removedItem: false,
  }),
  getters: {
    getCounter: (state) => state.counter,
    getSelectedTab: (state) => state.selectedTab,
    getTimer: (state) => state.timer,
    getRemovedItem: (state) => state.removedItem,
  },
  actions: {
    addCounter(value:number) {
      this.counter = value;
    },
    addTimer(value:number) {
      this.timer = value;
    },
  },
});

export const piniaUser = defineStore('piniaUser', {
  state: () => ({
    login: true,
    jwt: '',
  }),
  getters: {
    getLogin: (state) => state.login,
    getJwt: (state) => state.jwt,
    getIsLogged: (state) => state.jwt !== '',
  },
  actions: {
    setLogin(value:boolean) {
      this.login = value;
    },
    setJwt(value:string) {
      this.jwt = value;
      window.localStorage.setItem('jwt', this.jwt);
    },
    getJwtLocal() {
      const jwtToken = window.localStorage.getItem('jwt');
      if (jwtToken && jwtToken !== '') {
        this.jwt = jwtToken;
      }
    },
    logout() {
      this.jwt = '';
      window.localStorage.removeItem('jwt');
    },
  },
});
