
import UserViewLogged from '@/components/user/UserViewLogged.vue';
import UserViewLogin from '@/components/user/UserViewLogin.vue';
import UserViewSignIn from '@/components/user/UserViewSignIn.vue';
import { computed } from 'vue';
import { piniaUser } from '@/pinia';

export default {
  components: {
    UserViewLogged,
    UserViewLogin,
    UserViewSignIn,
  },
  setup() {
    const pinia = piniaUser();
    const login = computed(() => pinia.getLogin);
    const isLogged = computed(() => pinia.getIsLogged);

    return {
      isLogged,
      login,
    };
  },
};
