<template>
  <div class="container py-5 min-vh-50">
    <div v-if="categories" class="experience-categories-container mb-3">
      <a class="experience-categories-item" :class="selectedCategory(0)" @click="selectCategory(0)" @keydown="selectCategory(0)">Tutto 😎</a>
      <a v-for="c in categories" :key="c.id" class="experience-categories-item"
         :class="selectedCategory(c.id)" @click="selectCategory(c.id)" @keydown="selectCategory(c.id)">
        {{ categoryName(c) }}
      </a>
    </div>
    <div v-if="products.length>0" class="experience-products-container">
      <router-link :to="{ name: 'ExperienceDetail', params: { id: p.code }}"
                   v-for="p in products" :key="p.code" class="col-12 col-md-6 col-lg-4 experience-products-item">
        <ExperienceItem :merch="p"></ExperienceItem>
      </router-link>
    </div>
    <div v-else>
      {{ $t('common.emptysearch') }}
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue';
import api from '@/core/api';
import ExperienceItem from '@/components/ExperienceItem.vue';

export default {
  components: {
    ExperienceItem,
  },
  setup() {
    const { get, post } = api();
    const categories = ref([]);
    const products = ref([]);
    const idCategory = ref(0);

    function categoryName(category) {
      return category?.locs[0]?.name ?? '';
    }

    function selectCategory(id) {
      idCategory.value = id;
    }

    function selectedCategory(id) {
      return idCategory.value === id ? 'selected' : '';
    }

    onMounted(() => {
      get('getExperienceCategories')
        .then((res) => {
          categories.value = res?.data ?? [];
          const data = {
            id_category: idCategory.value,
          };
          post('getExperiences', data)
            .then((res2) => {
              products.value = res2?.data ?? [];
            });
        });
    });

    watch(() => idCategory.value, () => {
      const data = {
        id_category: idCategory.value,
      };
      post('getExperiences', data)
        .then((res2) => {
          products.value = res2?.data ?? [];
        });
    });

    return {
      products,
      categories,
      categoryName,
      idCategory,
      selectCategory,
      selectedCategory,
    };
  },
};
</script>

<style lang="scss">
.experience-categories-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  .experience-categories-item {
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    color: black;
  }
  .experience-categories-item:hover {
    color: #B82620;
  }
  .experience-categories-item.selected {
    color: #B82620;
  }
}
.experience-products-container {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  .experience-products-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    text-decoration: none;
    .experience-products-item-image {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 500px;
      position: relative;
      img {
        max-width: 100%;
        width:100%;
        object-fit: cover;
        height: 100%;
      }
      .experience-products-item-shadow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));
      }
      .experience-products-item-container {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 25px;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 5px;
        .experience-products-item-name {
          font-family: DM Sans;
          font-size: 20px;
          font-weight: bold;
          color: #FFF;
        }
        .experience-products-item-price {
          font-family: DM Sans;
          font-size: 20px;
          color: #FFF;
          .experience-products-item-price-info {
            font-size: 15px;
          }
        }
        .experience-products-item-separator {
          width: 100%;
          height: 2px;
          background-color: white;
        }
      }
    }
  }
}
</style>
