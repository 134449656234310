
import { onMounted, onUnmounted, ref } from 'vue';
import { setCarousel, destroyCarousel } from '@/core/carousel/';

export default {
  setup() {
    const experiencesIndex = ref(0);
    const experiences = [
      {
        image: './assets/pc_top_experience_1.png',
        title: 'Violin Experience',
        description: 'Hai mai sentito il profumo del legno<br>lavorato nella bottega di un liutaio?<br>Vieni a provare un’esperienza unica,<br>circondato da piallette, acciarini,<br>scalpelli e sgorbie come Stradivari nel<br>XV secolo!',
        link: 'https://experience.procremona.it/it/negozio/visite-guidate/violin-experience/',
      },
      {
        image: './assets/pc_top_experience_2.png',
        title: 'Festa del Torrone…<br>ti racconto Cremona',
        description: 'Cremona, città da ascoltare…e da<br>mangiare! In occasione della Festa<br>del Torrone, proponiamo un percorso<br>per scoprire la città non solo dal<br>punto di vista della sua ricchissima<br>storia millenaria, ma anche sotto<br>l’aspetto della cultura gastronomica,<br>con il torrone, i marubini, la mostarda<br>e l’immancabile salame!',
        link: 'https://experience.procremona.it/it/negozio/visite-guidate/ti-racconto-cremona-2022/',
      },
      {
        image: './assets/pc_top_experience_3.png',
        title: 'Academia<br>Cremonensis',
        description: 'Il prestigioso palazzo ottocentesco<br>Mina Bolzesi, sede dell’Academia<br>Cremonensis, la scuola privata di<br>Liuteria e Archetteria situata nel<br>cuore della città, ti accoglierà per<br>scoprire le tecniche di costruzione<br>del violino e dell’archetto, e assistere<br>alla comparazione musicale tra viola<br>e violino.',
        link: 'https://experience.procremona.it/it/negozio/visite-guidate/academia-cremonensis-comparazione-musicale-violino-viola/',
      },
    ];

    function before() {
      if (experiencesIndex.value === 0) {
        experiencesIndex.value = experiences.length - 1;
      } else {
        experiencesIndex.value -= 1;
      }
    }

    function next() {
      if (experiencesIndex.value === (experiences.length - 1)) {
        experiencesIndex.value = 0;
      } else {
        experiencesIndex.value += 1;
      }
    }

    onMounted(() => {
      destroyCarousel('#carouselpc');
      setCarousel('#carouselpc');
    });

    onUnmounted(() => {
      destroyCarousel('#carouselpc');
    });

    return {
      experiencesIndex,
      experiences,
      before,
      next,
    };
  },
};
