import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserViewLogged = _resolveComponent("UserViewLogged")!
  const _component_UserViewLogin = _resolveComponent("UserViewLogin")!
  const _component_UserViewSignIn = _resolveComponent("UserViewSignIn")!

  return ($setup.isLogged)
    ? (_openBlock(), _createBlock(_component_UserViewLogged, {
        key: 0,
        "from-cart": true
      }))
    : ($setup.login)
      ? (_openBlock(), _createBlock(_component_UserViewLogin, { key: 1 }))
      : (_openBlock(), _createBlock(_component_UserViewSignIn, { key: 2 }))
}