<template>
  <footer class="my-5">
    <div class="container">
      <div class="d-flex justify-content-center">
        <img src="@/assets/logo.svg" class="header-logo" alt="logo">
      </div>
      <div class="d-flex justify-content-center my-5 gap-5 align-items-center">
        <a href="https://www.youtube.com/@procremona9647" target="_blank"><span hidden>bi-youtube</span><i class="bi bi-youtube fs-2 text-white"></i></a>
        <a href="https://www.facebook.com/procremona" target="_blank"><span hidden>bi-facebook</span><i class="bi bi-facebook fs-3 text-white"></i></a>
        <a href="https://www.instagram.com/procremona/" target="_blank"><span hidden>bi-instagram</span><i class="bi bi-instagram fs-3 text-white"></i></a>
        <a href="https://it.linkedin.com/company/procremona" target="_blank"><span hidden>bi-linkedin</span><i class="bi bi-linkedin fs-3 text-white"></i></a>
      </div>
      <div class="row justify-content-center">
        <div class="col-6 col-md-2 mb-3">
          <ul class="nav flex-column text-center">
            <li class="nav-item mb-2"><router-link to="/merch" class="nav-link p-0 text-white footer-nav-link">{{ $t('views.merch') }}</router-link></li>
            <li class="nav-item mb-2"><router-link to="/experiences" class="nav-link p-0 text-white">{{ $t('views.experiences') }}</router-link></li>
            <!--<li class="nav-item mb-2"><a href="https://experience.procremona.it/it/" target="_blank" class="nav-link p-0 text-white">{{ $t('views.experiences') }}</a></li>-->
            <li class="nav-item mb-2"><router-link to="/contacts" class="nav-link p-0 text-white footer-nav-link">{{ $t('views.contacts') }}</router-link></li>
            <li class="nav-item mb-2"><router-link to="/conditions" class="nav-link p-0 text-white footer-nav-link">{{ $t('views.conditions') }}</router-link></li>
          </ul>
        </div>
      </div>
      <div class="d-flex justify-content-end pt-2 border-top">
        <a href="https://www.iubenda.com/privacy-policy/19232192" class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>
      </div>
      <div class="d-flex justify-content-center pt-2">
        <p class="text-white">{{ $t('footer.company') }}</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
  .footer-nav-link {
    font-family: Poppins;
    font-size: 15px;
  }
</style>
