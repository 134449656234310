<template>
  <div class="container py-5 min-vh-50">
    <span class="cart-title">{{ $t('views.cart') }}</span>
    <div class="cart-container-tabs ">
      <div class="cart-container-tabs-item selected">{{ $t('common.cart') }}</div>
      <div class="divider"></div>
      <div class="cart-container-tabs-item selected">{{ $t('common.editdata') }}</div>
      <div class="divider center"></div>
      <div class="cart-container-tabs-item selected">{{ $t('common.summary') }}</div>
      <div class="divider"></div>
      <div class="cart-container-tabs-item selected">{{ $t('common.payment') }}</div>
    </div>
    <div class="cart-container-body">
      <CartViewPayment></CartViewPayment>
    </div>
  </div>
</template>

<script>
import CartViewPayment from '@/components/cart/CartViewPayment.vue';
import api from '@/core/api';
import { piniaCart } from '@/pinia';

export default {
  components: {
    CartViewPayment,
  },
  setup() {
    const { get } = api();
    const pinia = piniaCart();

    get('resetCartCount')
      .then((res) => {
        const count = res?.data?.count ?? 0;
        const timer = res?.data?.expiration ?? 0;
        pinia.addCounter(count);
        pinia.addTimer(timer);
      });
  },
};
</script>

<style lang="scss" scoped>
  .cart-title {
    font-family: Poppins;
    font-size: 30px;
    font-weight: bold;
    color: #B82620;
  }
  .cart-container-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 25px;
    .divider {
      flex-grow: 1;
      border-bottom: 2px solid #B82620;
    }
    .cart-container-tabs-item {
      border: 2px solid #6E6E6E;
      border-radius: 25px;
      width: 250px;
      padding: 5px;
      text-align: center;
      font-family: Poppins;
      font-weight: bold;
      color: #6E6E6E;
    }
    .cart-container-tabs-item.selected {
      color: #FFF;
      background-color: #B82620;
    }
  }
  @media (max-width: 767.98px) {
    .cart-container-tabs {
      justify-content: center;
      .cart-container-tabs-item {
        width: 100%;
        margin-bottom: 15px;
      }
      .divider {
        display: none;
      }
    }
  }
  @media (max-width: 991.98px) {
    .cart-container-tabs {
      .divider.center {
        display: none;
      }
    }
  }
  @media (max-width: 1199.98px) {
    .cart-container-tabs {
      .cart-container-tabs-item {
        margin-bottom: 15px;
      }
      .divider {
        margin-bottom: 15px;
      }
    }
  }
  .cart-container-body {
    display: flex;
    margin-top: 25px;
  }
</style>
