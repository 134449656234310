<template>
  <div class="container py-5 min-vh-50">
    <span class="conditions-title">PRO CREMONA - Termini e condizioni di vendita</span>
    <b><u>Spedizioni PRO CREMONA</u></b>
    <p>
      Pro Cremona spedisce in tutta Italia - isole comprese, e verso l’Estero, ovvero tutta Europa e UK.
      Le tempistiche di consegna variano tra i 2 e i 5 giorni lavorativi per l’Italia, mentre per l’Estero le
      tempistiche variano in funzione della distanza.
      Le spedizioni sono gratuite sopra i 35,00 € di spesa.
      Si potrà consultare lo stato dell’ordine in qualsiasi momento attraverso il link che ti arriverà via e-mail.
      Oltre ai tempi di spedizione, andranno considerati 1-2 giorni lavorativi per processare l’ordine.
      Ogni ordine effettuato dopo le ore 15 verrà processato a partire dal giorno successivo.
      Durante il periodo di saldi o promozioni, queste tempistiche potrebbero variare.
      Quando il tuo ordine lascia i nostri magazzini, riceverai un’e-mail dal nostro partner per le spedizioni
      contenenti il tracking number.
    </p>
    <b><u>Modalità di consegna / ritiro</u></b>
    <p>
      Ciascun articolo ordinato sarà recapitato direttamente a domicilio, all'indirizzo indicato in fase di
      registrazione o acquisto.
      In Italia c’è la possibilità di sfruttare le tariffe agevolate del servizio DIRECT2SHOP, che permette di
      scegliere il proprio punto di ritiro BRT-fremopoint direttamente dal checkout della piattaforma
      e-commerce.
    </p>
    <b><u>Modalità di consegna / ritiro</u></b>
    <p>
      Sarà necessario inviare una richiesta di "Recesso acquisti" via e-mail a resi@procremona.it. Verranno
      fornite tutte le istruzioni per poter restituire il prodotto e ottenere il rimborso di quanto pagato.
      Per cambiare un articolo bisognerà effettuare la procedura di reso e fare un nuovo ordine con il
      prodotto della taglia o del colore desiderato.
      Le spese di spedizione relative alla restituzione o al cambio del bene sono a carico del cliente. Anche
      in questo caso, per l’Italia, sarà possibile beneficiare di una tariffa agevolata attraverso il servizio
      DIRECT2SHOP, ovvero riportando manualmente il prodotto da restituire in un centro Ritiro BRT.
      Ti invitiamo, in ogni caso, a visionare sempre la guida alle taglie presente in ogni scheda prodotto
      prima di procedere all’ordine di capi di abbigliamento.
    </p>
    <span class="conditions-subtitle">Condizioni Generali di Vendita</span>
    <p>
      L’offerta e la vendita di opere di ingegno a carattere creativo presenti sul nostro sito web
      (“procremona.it“) sono regolate da queste Condizioni Generali di Vendita.
      Le opere di ingegno a carattere creativo acquistate su Pro Cremona sono vendute direttamente da
      PROWORLD SRLS (“Pro Cremona” o il “Venditore”), con sede legale in Italia, via Ceresole, 16 –
      26100 Cremona, C.F. e P.IVA n. 01618080194.
      Se hai bisogno di ulteriori informazioni vai nell’area FAQ. Potrai trovare informazioni in merito ad
      ordini e spedizioni, ai rimborsi ed alla restituzione dei prodotti acquistati su procremona.it.
      Per qualsiasi altra informazione legale, consulta le sezioni Privacy Policy e Resi&Spedizioni.
    </p>
    <ol>
      <li>
        <b>La nostra politica commerciale</b>
        <ul>
          <li>Il Venditore offre in vendita, su procremona.it, le proprie opere di ingegno a carattere
            creativo esclusivamente nei confronti dei propri utenti finali che siano “consumatori”.</li>
          <li>Quando parliamo di “consumatore” ci riferiamo a qualsiasi persona fisica che agisce
          su procremona.it con finalità non riferibili alla propria attività commerciale,
          imprenditoriale o professionale, eventualmente svolta. Se non sei un “consumatore”,
          ti invitiamo ad astenerti dal concludere transazioni commerciali attraverso
          procremona.it.</li>
          <li>Il Venditore si riserva il diritto di non dare seguito ad ordini provenienti da soggetti
          diversi dal “consumatore” o comunque ad ordini che non siano conformi alla propria
          politica.</li>
          <li>Queste Condizioni Generali di Vendita regolano esclusivamente l’offerta, l’inoltro e
          l’accettazione di ordini d’acquisto di prodotti su Pro Cremona tra gli utenti di
          procremona.it ed il Venditore.</li>
          <li>Le Condizioni Generali di Vendita non regolano invece la fornitura di servizi o la
          vendita di prodotti da parte di soggetti diversi dal Venditore che siano presenti su
          procremona.it tramite link, banner o altri collegamenti ipertestuali. Ti consigliamo,
          prima di inoltrare ordini ed acquistare prodotti e servizi da soggetti diversi dal
          Venditore, di verificare le loro condizioni di vendita, perché il Venditore non è
          responsabile della fornitura di servizi da parte di terzi soggetti diversi dal Venditore o
          della conclusione di operazioni di commercio elettronico tra gli utenti di procremona.it
          e soggetti terzi.</li>
        </ul>
      </li>
      <li>
        <b>Come concludere il contratto con Pro Cremona</b>
        <ul>
          <li>Per concludere il contratto di acquisto di uno o più prodotti su procremona.it, dovrai
          compilare il modulo d’ordine in formato elettronico e trasmetterlo al Venditore, per via
          telematica, seguendo le relative istruzioni.</li>
          <li>Nel modulo d’ordine, visualizzato immediatamente prima della conclusione del
          contratto d’acquisto, ti forniremo informazioni di sintesi circa le caratteristiche
          essenziali di ciascun prodotto ordinato, il prezzo (comprensivo di tutte le tasse o
          imposte applicabili) ed i costi di spedizione (ivi compresi gli eventuali costi
          supplementari da te sostenuti per aver scelto una tipologia di spedizione e consegna
          diversa e/o più rapida di quella standard). Il contratto è concluso quando il Venditore
          riceve, per via telematica, il tuo modulo d’ordine, previa verifica della correttezza dei
          dati relativi al tuo ordine.</li>
          <li>Il modulo d’ordine sarà archiviato presso la nostra banca dati per il periodo di tempo
          necessario all’evasione degli ordini (1-2 giorni lavorativi) e comunque nei termini di
          legge. Potrai accedere al tuo modulo d’ordine, consultando la sezione ‘Ordini‘.</li>
          <li>Al momento di procedere alla trasmissione del modulo d’ordine sarai avvertito che
          tale inoltro implica l’obbligo di corrispondere il prezzo indicato. Prima di procedere
          alla trasmissione del modulo d’ordine, ti sarà anche richiesto di individuare e di
          correggere eventuali errori di inserimento dei dati.</li>
          <li>La lingua a disposizione per concludere il contratto con il Venditore è l’italiano.</li>
          <li>Concluso il contratto, Pro Cremona prenderà in carico il tuo ordine d’acquisto. Nella
          tipologia di ordine “PRE-ORDINE” accetti di pagare immediatamente al momento
          dell’ordine con consegna differita e non nell’immediato come segnalato nella scheda
          prodotto.</li>
          <li>Il Venditore potrà non dar corso a tuoi ordini d’acquisto che non diano sufficienti
          garanzie di solvibilità o che risultino incompleti o non corretti ovvero in caso di
          indisponibilità dei prodotti. In questi casi, provvederemo ad informarti per posta
          elettronica che il contratto non è concluso e che il Venditore non ha dato seguito al
          tuo ordine d’acquisto specificandone i motivi. Qualora i prodotti, presentati su
          procremona.it, non siano più disponibili o in vendita al momento del tuo ultimo accesso al sito ovvero dell’invio del modulo d’ordine, sarà cura del Venditore
          comunicarti, tempestivamente ed in ogni caso entro trenta (30) giorni a decorrere dal
          giorno successivo a quello in cui avrai trasmesso il tuo ordine al Venditore,
          l’eventuale indisponibilità dei prodotti ordinati. In caso di inoltro del modulo d’ordine e
          pagamento del prezzo, il Venditore provvederà a rimborsare, senza indebito ritardo,
          quanto da te già anticipato ed il contratto si intenderà risolto tra le parti.</li>
          <li>Con la trasmissione telematica del modulo d’ordine, accetti incondizionatamente e ti
          impegni ad osservare, nei rapporti con il Venditore, queste Condizioni Generali di
          Vendita. Se non condividi alcuni dei termini riportati nelle Condizioni Generali di
          Vendita, ti invitiamo a non inoltrare il modulo d’ordine per l’acquisto dei prodotti su
          procremona.it.</li>
          <li>Con la trasmissione del modulo d’ordine confermi di conoscere ed accettare le
          Condizioni Generali di Vendita e le ulteriori informazioni contenute in procremona.it,
          anche richiamate tramite link, comprese le Condizioni Generali d’Uso e la Privacy
          Policy e l’Informativa sul diritto di recesso.</li>
          <li>Concluso il contratto, il Venditore ti trasmetterà, per posta elettronica, una ricevuta
          dell’ordine d’acquisto.</li>
          <li>Ti ricordiamo che il prodotto che acquisti è destinato esclusivamente al Paese nel
          quale effettui l’ordine; pertanto, se decidi di immettere il prodotto in un Paese diverso,
          sei responsabile dell’immissione e sei tenuto a seguire le normative e le restrizioni
          applicabili sia per l’esportazione dal Paese in cui hai acquistato l’oggetto che per
          l’importazione nel Paese in cui intendi portarlo. Pro Cremona declina sin d’ora ogni
          responsabilità in merito.</li>
          <li>Acquistando un prodotto dichiari di aver verificato che tale oggetto non lede proprietà
          intellettuale altrui, sia sotto forma di disegno, invenzioni, nomi, loghi, fotografie in
          forma esplicita o sotto forma di parodia. Dopo i dovuti accertamenti, procedendo
          all’acquisto non potrai rivalerti con Pro Cremona per eventuali controversie con terze
          parti e i capi acquistati non potranno essere utilizzati né dal diretto interessato o da
          terze parti come prova di eventuale violazione dell’uso improprio di proprietà
          intellettuali altrui.</li>
        </ul>
      </li>
      <li>
        <b>Garanzie ed indicazione dei prezzi dei prodotti</b>
        <ul>
          <li>Su procremona.it sono offerti in vendita prodotti frutto di opera d’ingegno a carattere
          creativo del Titolare. Questi prodotti sono realizzati direttamente dal Venditore
          personalizzandoli sulla richiesta del Cliente al momento dell’acquisto, pertanto è
          possibile che presentino piccole imperfezioni dovute alla lavorazione.</li>
          <li>Le caratteristiche essenziali dei prodotti sono presentate su procremona.it all’interno
          di ciascuna scheda prodotto. Le immagini ed i colori dei prodotti offerti in vendita su
          procremona.it potrebbero tuttavia non essere corrispondenti a quelli reali per effetto
          del browser Internet e del monitor utilizzato, la maggior parte delle fotografie dei
          prodotti sono realizzate tramite tecniche grafiche di manipolazioni delle immagini e
          pertanto non sono le reali immagini dei prodotti anche se molto simili a quelle reali.</li>
          <li>I prezzi dei prodotti potrebbero essere soggetti ad aggiornamenti. Accertarti del
          prezzo finale di vendita prima di inoltrare il relativo modulo d’ordine.</li>
          <li>Le richieste di acquisto provenienti da Paesi non compresi fra quelli indicati come di
          possibile destinazione non potranno essere accettate dal Venditore.</li>
          <li>Tutti i prodotti sono muniti di cartellino identificativo. Ti chiediamo di non rimuovere il
          cartellino dai prodotti acquistati, dei quali costituiscono parte integrante.</li>
          <li>Ai sensi dell’art. 57, II comma, del Codice del Consumo, sarai responsabile della
          diminuzione del valore dei prodotti acquistati su procremona.it conseguente ad un
          utilizzo dei prodotti stessi diverso da quella necessario per accertarne la natura, le
          caratteristiche e il funzionamento.
          Pertanto, il Venditore, in caso di esercizio del tuo diritto di recesso, ha la
          facoltà di non accettare la restituzione o non rimborsare integralmente le
          somme corrisposte per l’acquisto, in relazione a quei prodotti che siano
          sprovvisti del relativo cartellino o che siano stati alterati nelle loro
          caratteristiche essenziali e qualitative o che siano stati danneggiati.</li>
        </ul>
      </li>
      <li>
        <b>Pagamenti</b>
        <ul>
          <li>Per il pagamento del prezzo dei prodotti e delle relative spese di spedizione e
          consegna potrai seguire una delle modalità indicate in fase d’ordine.</li>
          <li>In caso di pagamento mediante carta di credito, le informazioni finanziarie (ad
          esempio, il numero della carta di credito/debito o la data della sua scadenza) saranno
          inoltrate, tramite protocollo crittografato, a Stripe ad altre banche, che forniscono i
          relativi servizi di pagamento elettronico a distanza, senza che terzi possano, in alcun
          modo, avervi accesso. Tali informazioni, inoltre, non saranno mai utilizzate dal
          Venditore se non per completare le procedure relative al tuo acquisto e per emettere i
          relativi rimborsi in caso di eventuali restituzioni dei prodotti, a seguito di esercizio del
          tuo diritto di recesso, ovvero qualora si renda necessario prevenire o segnalare alle
          forze di polizia la commissione di frodi su procremona.it.
          Il prezzo per l’acquisto dei prodotti e le spese di spedizione e consegna, come
          indicato nel modulo d’ordine, saranno addebitati sul tuo conto corrente al momento
          dell’acquisto dei prodotti ordinati.</li>
        </ul>
      </li>
      <li>
        <b>Voucher a valore</b>
        <ul>
          <li>I voucher a valore sono codici personalizzati, chiamati anche buoni sconto o coupon,
          che consentono di usufruire di uno sconto sugli acquisti effettuati su procremona.it</li>
          <li>La casella coupon viene visualizzata sulle prime pagine del processo d’acquisto: una
          volta inseriti gli articoli nella Shopping Bag, inserisci il tuo codice nella casella sconto.</li>
          <li>Limitazioni all’utilizzo del voucher a valore:
            <ul>
              <li>Il voucher a valore è utilizzabile una sola volta;</li>
              <li>Il voucher a valore non potrà in alcun modo essere convertito in denaro;</li>
              <li>Il voucher a valore non è applicabile a prodotti già in sconto;</li>
              <li>Il voucher a valore non è applicabile alle spese di spedizione;</li>
              <li>Non sarà possibile utilizzare più voucher a valore nello stesso ordine.</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <b>Spedizione e consegna dei prodotti</b>
        <ul>
          <li>Per conoscere le specifiche modalità di spedizione e consegna dei prodotti, accedi
          alla sezione Spedizioni & Resi, all’interno dell’area Servizio Clienti. Ti preghiamo di
          prestare attenzione a quanto riportato in questa sezione perché le indicazioni ivi
          contenute formano parte integrante e sostanziale delle presenti Condizioni Generali di Vendita e, pertanto, si ritengono da te integralmente conosciute ed accettate al
          momento della trasmissione del modulo d’ordine.</li>
          <li>La spedizione (Italia) viene effettuata tramite corriere Bartolini e solitamente i tempi
          di consegna sono di 2/5 giorni lavorativi. I giorni lavorativi sono dal lunedì al
          venerdì. I tempi di consegna indicano i giorni che il corriere necessità per la
          consegna dopo che gli è stata affidata la merce dalla logistica. Condizioni meteo,
          prossimità di periodi di festività, periodi di saldi e promozioni possono far allungare i
          tempi di consegna sopra indicati, che si intendono indicativi e non vincolanti. TEMPI
          AGGIUNTIVI DI PREPARAZIONE DELL’ORDINE: 1-2 GIORNI LAVORATIVI.</li>
          <li>Per gli acquisti effettuati con modalità ‘PRE-ORDER’ (PRE-ORDINE), i tempi di
          preparazione e consegna partiranno dalla data di disponibilità della merce indicata
          nella scheda tecnica/descrittiva dei singoli prodotti. Nel caso vengano acquistati
          insieme ad un “PRE-ORDINE” ulteriori articoli che non sono con consegna differita
          denominata “PRE-ORDINE”, verrà consegnato l’intero ordine con una singola
          spedizione in data differita e riferita all’articolo ordinato in “PRE-ORDINE”.</li>
          <li>Il corriere farà massimo due tentativi di consegna per il tuo ordine e, qualora non
          andassero a buon fine, porterà il pacco presso l’ufficio postale di zona in cui resterà
          in giacenza per 10 giorni. Se per qualunque motivazione non ritirerai il pacco
          contenente il tuo ordine dopo che il corriere ha tentato di recapitare lo stesso per due
          volte, dovrai pagare nuovamente le spese di spedizione di 7,00€.</li>
          <li>In caso di indirizzo errato/informazioni mancanti che possono inficiare la corretta
          consegna del tuo ordine, l’ordine verrà annullato e ti verrà effettuato un rimborso
          decurtato di 7,00€ di penale da parte del corriere.</li>
        </ul>
      </li>
      <li>
        <b>Assistenza clienti</b>
        <ul>
          <li>Potrai richiedere qualsiasi informazione tramite i nostri servizi di assistenza. Per
          maggiori chiarimenti accedi all’area Servizio Clienti.</li>
          <li>Ci piacerebbe che i nostri canali Social (Facebook, Instagram e Twitter) fossero
          anche un luogo di dialogo e di condivisione. Le pagine sono aperte ai commenti di
          tutti e l’obiettivo è quello di aprire un dialogo con tutti gli utenti, di seguito in breve i
          comportamenti non sono ammessi:
          <ul>
            <li>Siamo felici di ricevere i vostri commenti quando sono coerenti e pertinenti: ci
            riserviamo di rimuovere quelli non inerenti alle singole discussioni, quelli che
            riguardano attività di siti esterni, promozioni commerciali non autorizzate da
            Pro Cremona.</li>
            <li>Cancelleremo i commenti di tutti coloro che utilizzano un linguaggio in
            violazione delle regole della buona educazione, scurrile e finalizzato a ledere
            la reputazione di chi gestisce o modera questa pagina o degli altri utenti, non
            saranno inoltre tollerati commenti offensivi a minoranze etniche, politiche,
            religiose o di altro tipo. Non saranno ammesse condotte in violazione del
            diritto d’autore e/o che vengano utilizzati marchi registrati senza previa
            autorizzazione.</li>
          </ul>
          </li>
          <li>Ti ricordiamo quindi che non saranno tollerate attività illegali volte a diffamare o
          minacciare, diffondere dati personali di terzi senza essere autorizzati. In questo caso,
          ci rivolgeremo alle autorità competenti.
          Qualora lo reputassimo opportuno, segnaleremo a Facebook, Instagram e Twitter i
          contenuti in questione. Dopo reiterati comportamenti lesivi della presente policy,
          inoltre, l’utente responsabile potrà essere bannato.
          Ti preghiamo inoltre di non condividere alcuna informazione riguardante, direttamente
          o indirettamente, i Tuoi ordini di acquisto presso i nostri siti internet né, tantomeno,
          nessuna informazione riguardante vostri dati personali.
          Per richieste inerenti l’assistenza è opportuno contattarci tramite messaggio privato.
          In caso contrario, ti inviteremo a continuare la conversazione in privato offrendoti,
          così, un accesso diretto e riservato al nostro Servizio Clienti che si riserva di non
          rispondere in pubblico.</li>
        </ul>
      </li>
      <li>
        <b>Diritto di recesso ai sensi degli artt. 52 e ss. del Codice del Consumo</b>
        <ul>
          <li>Ai sensi dell’art. 52 del Codice del Consumo, hai il diritto di recedere dal contratto
          concluso con il Venditore, senza alcuna penalità e senza specificarne il motivo, entro quattordici (14) giorni di calendario, decorrenti dal giorno del ricevimento dei prodotti
          acquistati su procremona.it.</li>
          <li>Per recedere dal contratto puoi inviarcene comunicazione nell’apposito form presente
          nell’area Servizio Clienti.</li>
          <li>Una volta esercitato il recesso dal contratto, dovrai restituire i prodotti al Venditore
          consegnandoli al corriere di tua scelta per la spedizione entro sette (7) giorni che
          decorrono da quando hai comunicato al Venditore la tua decisione di recedere dal
          contratto.</li>
          <li>Le uniche spese a tuo carico sono quelle di restituzione dei prodotti acquistati.
          Rimane a tuo carico la responsabilità in caso di smarrimento o danneggiamento dei
          prodotti durante il trasporto, che sia dovuto ad una tua negligente scelta del vettore
          e/o delle modalità di spedizione.</li>
          <li>Il Diritto di Recesso – oltre al rispetto dei termini e delle modalità di cui agli artt. 52 e
          ss. del Codice del Consumo, descritti ai precedenti punti 8.1, 8.2, 8.3 e 8.4 – si
          intende esercitato correttamente qualora siano interamente rispettate anche le
          seguenti condizioni:
          <ul>
            <li>la richiesta di reso deve essere trasmessa al Venditore entro quattordici (14)
            giorni dal ricevimento dei prodotti;</li>
            <li>i prodotti non devono essere stati utilizzati, indossati, lavati;</li>
            <li>il cartellino identificativo deve essere ancora attaccato ai prodotti con il sigillo
            monouso, che costituisce parte integrante dei beni;</li>
            <li>i prodotti devono essere restituiti nella loro confezione originale;</li>
            <li>i prodotti resi devono essere consegnati allo spedizioniere entro sette (7)
            giorni che decorrono da quando hai comunicato al Venditore la tua decisione
            di recedere dal contratto;</li>
            <li>i prodotti non devono essere danneggiati.</li>
          </ul>
          </li>
          <li>Se il Diritto di Recesso è esercitato seguendo le modalità ed i termini indicati in
          questo paragrafo 8, il Venditore provvede a rimborsare le eventuali somme già
          incassate per l’acquisto dei prodotti secondo le modalità ed i termini previsti.</li>
          <li>Le somme ti saranno rimborsate nel minore tempo possibile ed, in ogni caso, entro
          trenta (30) giorni dalla data in cui il Venditore è venuto a conoscenza dell’esercizio
          del tuo diritto di recesso, provvederemo ad attivare le procedure di rimborso, una
          volta verificata la corretta esecuzione dei termini e delle condizioni sopra indicate.</li>
          <li>Qualora non siano rispettati le modalità ed i termini per l’esercizio del tuo diritto di
          recesso non avrai diritto al rimborso delle somme già corrisposte al Venditore. Entro
          14 giorni dall’invio della e-mail con cui ti sarà comunicata la mancata accettazione del
          reso, potrai scegliere di riottenere, a tue spese, i prodotti nello stato in cui sono stati
          restituiti al Venditore, dandone comunicazione al Venditore stesso, secondo le
          modalità che ti saranno comunicate. In caso contrario, il Venditore potrà trattenere i
          prodotti, oltre alle somme già pagate per il loro acquisto.Qualora non siano rispettate
          le condizioni di cui alle lettere b), c) e d) del precedente paragrafo 7.7, non avrai
          diritto all’integrale rimborso delle somme già corrisposte al Venditore. Sarai, infatti,
          responsabile della diminuzione di valore dei prodotti restituiti, conseguente da un
          utilizzo diverso da quello necessario allo scopo di consentirti di accertare la natura, le
          caratteristiche ed il funzionamento dei prodotti stessi. In questa ipotesi, dal rimborso
          previsto sarà detratta una percentuale compresa tra il 10 ed il 90 percento delle
          somme da te corrisposte al Venditore per l’acquisto dei prodotti restituiti, secondo
          quanto ti sarà specificamente comunicato, via posta elettronica, dal Venditore. Entro
          14 giorni dall’invio della e-mail con cui ti sarà comunicata la somma detratta dal
          rimborso, potrai scegliere di riottenere, a tue spese, i prodotti nello stato in cui sono
          stati restituiti al Venditore, dandone comunicazione al Venditore stesso, secondo le
          modalità che ti saranno comunicate. In caso contrario, il Venditore potrà trattenere i
          prodotti e un importo corrispondente alla percentuale detratta sul rimborso.</li>
        </ul>
      </li>
      <li>
        <b>Tempi e modalità di rimborso</b>
        <ul>
          <li>Dopo la restituzione dei prodotti, il Venditore provvede ai necessari accertamenti
          relativi alla conformità degli stessi alle condizioni ed i termini indicati nel paragrafo 8.
          Nel caso in cui le verifiche si concludano positivamente, il Venditore provvede ad
          inviarti, via posta elettronica, la relativa conferma dell’accettazione dei prodotti così restituiti e provvedere ad effettuare il rimborso. Nel caso in cui le verifiche non si
          concludano positivamente, il Venditore provvede a comunicarti, via posta elettronica,
          la riscontrata sussistenza di una diminuzione di valore dei prodotti restituiti, derivante
          dal mancato rispetto, da parte tua, delle condizioni di cui alle lettere b) c) e d) del
          precedente paragrafo 8.3. Contestualmente il Venditore provvede a comunicarti,
          inoltre, l’importo che sarà detratto dalle somme da te corrisposte per l’acquisto dei
          prodotti restituiti e ad effettuare il rimborso parziale; ferma restando, in alternativa, la
          possibilità di riottenere, a tue spese, i prodotti nello stato in cui sono stati restituiti al
          Venditore, secondo quanto previsto al precedente paragrafo.</li>
          <li>Qualunque sia la modalità di pagamento da te utilizzata, il rimborso, integrale o
          parziale, è attivato dal Venditore ai sensi dell’art 56, I comma, del Codice del
          Consumo, nel minore tempo possibile e comunque entro quattordici (14) giorni dalla
          data in cui il Venditore è venuto a conoscenza dell’esercizio del tuo diritto di recesso
          previa verifica della corretta esecuzione del tuo diritto di recesso e verifica dei prodotti
          restituiti.</li>
          <li>Il Venditore esegue il rimborso utilizzando lo stesso mezzo di pagamento da te
          adoperato per l’acquisto dei prodotti restituiti. Qualora non vi sia corrispondenza tra il
          destinatario dei prodotti indicato nel modulo d’ordine e chi ha eseguito il pagamento
          delle somme dovute per il loro acquisto, il rimborso delle somme, in caso di esercizio
          del diritto di recesso, sarà eseguito dal Venditore, in ogni caso, nei confronti di chi ha
          effettuato il pagamento. Ti ricordiamo che la data di valuta del riaccredito è la stessa
          dell’addebito;</li>
        </ul>
      </li>
      <li>
        <b>Spese di spedizione per il reso o sostituzione.</b>
        <ul>
          <li>Le spese di spedizione per rendere la merce o sostituirla sono sempre a tuo carico
          (acquirente), sia per il rientro presso la nostra sede e che per l’eventuale recapito
          presso la tua abitazione del prodotto in sostituzione o qualora il prodotto reso nelle
          caratteristiche richieste da Pro Cremona.</li>
          <li>Le spese di spedizione per il reso o sostituzione sono a carico dell’acquirente, anche
          nel caso si sia usufruito della spedizione gratuita.</li>
        </ul>
      </li>
      <li>
        <b>Privacy</b>
        <ul>
          <li>Potrai ottenere informazioni su come trattiamo i tuoi dati personali accedendo alla sezione Privacy Policy.</li>
          <li>Ti preghiamo inoltre di leggere, se non l’avessi ancora fatto, i nostri Termini &
          Condizioni perché contengono importanti indicazioni su come trattiamo i dati
          personali dei nostri utenti e sui sistemi di sicurezza adottati.</li>
        </ul>
      </li>
      <li>
        <b>Modifica e aggiornamento</b>
        <ul>
          <li>Le Condizioni Generali di Vendita sono modificate di volta in volta anche in
            considerazione di eventuali mutamenti normativi. Le nuove Condizioni Generali di
            Vendita saranno efficaci dal momento che saranno pubblicati e visibili in questa
            stessa area del sito “Condizioni Generali di Vendita” su procremona.it</li>
        </ul>
      </li>
    </ol>
  </div>
</template>

<style lang="scss" scoped>
  .conditions-title {
    font-family: Poppins;
    font-size: 30px;
    font-weight: bold;
    color: #B82620;
    margin-bottom: 15px;
  }
  .conditions-subtitle {
    font-family: Poppins;
    font-size: 25px;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
  }
  span {
    display: block;
  }
  b {
    display: block;
  }
  .container {
    font-family: DM Sans;
  }
</style>
