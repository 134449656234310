import { Carousel } from 'bootstrap';

const setCarousel = (modalId: string): void => {
  const myCarouselElement = document.querySelector(modalId.toString());
  if (myCarouselElement) {
    const carousel = Carousel.getOrCreateInstance(myCarouselElement, {
      interval: 6000,
      pause: false,
      wrap: true,
      ride: 'carousel',
    });
    carousel.to(0);
  }
};

const destroyCarousel = (modalId: string): void => {
  const myCarouselElement = document.querySelector(modalId.toString());
  if (myCarouselElement) {
    const carousel = Carousel.getInstance(myCarouselElement);
    if (carousel) {
      carousel.dispose();
    }
  }
};

export { setCarousel, destroyCarousel };
