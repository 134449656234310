<template>
  <router-view/>
</template>

<style lang="scss">
  $primary: #B82620;
  @import "../node_modules/bootstrap/scss/bootstrap";
  @import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    display: none !important;
  }
  .fc-day-grid-container.fc-scroller {
    height: auto!important;
    overflow-y: auto;
  }
  .fc .fc-daygrid-day-number {
    color: #6E6E6E;
    text-decoration: none !important;
    font-family: Poppins;
    font-size: 20px;
  }
  .pc-selected-date {
    background-color: #B82620 !important;
    color: white !important;
    a {
      color: white !important;
    }
  }
  .fc .fc-day-disabled {
    background-color: unset !important;
  }
  .fc .fc-day-past {
    opacity: 0.3 !important;
  }
  .fc .fc-toolbar-title {
    font-size: 1.5em !important;
    font-family: Poppins !important;
  }
  .fc .fc-button-primary {
    background-color: #FFF !important;
  }
  .fc .fc-button {
    border-radius: 0px !important;
    padding: 1em !important;
  }
  .fc-prev-button {
    border-color: #B82620 !important;
    color: #B82620 !important;
  }
  .fc-next-button {
    border-color: #BCBCBC !important;
    color: #BCBCBC !important;
  }
  .fc-scrollgrid-section-header {
    display: none !important;
  }
  .fc .fc-daygrid-day-top {
    display: flex !important;
    justify-content: center !important;
  }
  .fc .fc-daygrid-day-frame {
    min-height: 100% !important;
    position: relative !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .fc-theme-standard td, .fc-theme-standard th {
    border: 1px solid #6E6E6E !important;
  }
  .fc .fc-highlight {
    background: #B82620 !important;
    color: #FFF !important;
  }
  .experience-container-calendar {
    .fc-scrollgrid {
      border-color: transparent !important;
    }

    .fc-scrollgrid td:last-of-type {
      border-right-color: transparent !important;
    }

    .fc-scrollgrid-section.fc-scrollgrid-section-body td[role='presentation'] {
      border-bottom-color: transparent !important;
    }

    [role='row']:last-of-type td {
      border-bottom-color: transparent !important;
    }
    th[role='presentation'] {
      border-right-color: transparent !important;
    }

    table {
      border-color: transparent !important;
    }

    .fc td:first-of-type {
      border-left-style: none !important;
    }

    .fc tr:first-of-type td {
      border-top-style: none !important;
    }

    .fc .fc-daygrid-day.fc-day-today {
      background-color: transparent !important;
      /* border: 3px solid #B82620 !important; */
      font-weight: bold;
    }

    tr[role='row']:last-of-type  {
      border-color: transparent !important;
    }
  }

  .carousel-caption {
    z-index: 10;
    display: flex;
    flex-direction: column;
    text-align: left !important;
  }
  .carousel-indicators {
    top: 15px !important;
    justify-content: space-between !important;
  }
  .carousel-item-img-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));
  }
  .carousel-indicators [data-bs-target] {
    position: relative !important;
    width: 100% !important;
    height: 5px !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  @keyframes storyAnimation {
    from { width: 0; }
    to { width: 100%; }
  }
  .carousel-indicators .active {
    background-color: rgba(255, 255, 255, 0.50);
  }
  .carousel-indicators .active span {
    content: '' !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;
    background: #FFFFFF !important;
    animation: storyAnimation 6s linear;
  }
  .carousel-item {
    .carousel-item-img {
      width: 100%;
      height: 90vh;
      object-fit: cover;
    }
  }
  .carousel-caption-title {
    font-family: Poppins;
    font-size: 40px;
    color: #FFF;
    display: block;
    font-weight: bold;
  }
  .carousel-caption-description {
    font-family: Work Sans;
    font-size: 12px;
    color: #FFF;
  }
  .carousel-caption-link {
    width: 200px;
    margin-left: auto;
  }

  .header-logo {
    height: 40px;
    width: auto;
  }
  .min-vh-50 {
    min-height: 50vh !important;
  }
  .btn-pc, btn-pc:hover {
    padding: 15px !important;
    border-radius: 35px !important;
    font-family: Poppins !important;
    font-size: 15px !important;
    font-weight: bold !important;
    color: white !important;
    height: 55px;
  }
  .btn-pc.btn-pc-primary {
    background-color: #B82620 !important;
  }
  .btn-pc.btn-pc-primary:hover {
    background-color: #a2221c !important;
  }
  .btn-pc.btn-pc-secondary {
    background-color: #6E6E6E !important;
  }
  .btn-pc.btn-pc-secondary:hover {
    background-color: #595959 !important;
  }
  .btn-pc.btn-pc-preorder {
    background-color: #397792 !important;
    min-width: 250px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    span {
      font-size: 22px !important;
    }
  }
  .btn-pc.btn-pc-preorder:hover {
    background-color: #2c5f75 !important;
  }
  @media (max-width: 767.98px) {
    .btn-pc.btn-pc-preorder {
      min-width: 50%;
    }
  }
</style>
