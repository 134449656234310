
import swalAlert from '@/alert';
import api from '@/core/api';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { computed, reactive } from 'vue';

export default {
  setup() {
    const { operationConfirm } = swalAlert();
    const { post } = api();
    const state = reactive({
      name: '',
      email: '',
      type: '',
      notes: '',
      privacy: '',
    });

    const rules = computed(() => ({
      name: {
        required,
      },
      email: {
        required,
      },
      type: {
        required,
      },
      notes: {
        required,
      },
      privacy: {
        required,
      },
    }));

    const v = useVuelidate(rules, state, { $scope: false });

    function status(validation: { $error: any; }) {
      return {
        'is-invalid': validation.$error,
      };
    }

    function submit(e: any) {
      e.preventDefault();
      v.value.$validate();
      if (!v.value.$invalid) {
        const data = {
          name: state.name,
          email: state.email,
          type: state.type,
          notes: state.notes,
        };
        post('contactSendEmail', data)
          .then(() => {
            operationConfirm('Messaggio inviato con successo')
              .then(() => {
                state.name = '';
                state.email = '';
                state.type = '';
                state.notes = '';
                state.privacy = '';
                v.value.$reset();
              });
          });
      }
    }

    return {
      state,
      rules,
      v,
      submit,
      status,
    };
  },
};
