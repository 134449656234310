import { Modal } from 'bootstrap';

const hideModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }

  const myModal = Modal.getInstance(modalEl);
  if (myModal) {
    myModal.hide();
  }
};

const showModal = (modalId: string): void => {
  const myModal = new Modal(document.getElementById(modalId.toString())!);
  myModal.show();
};

export { hideModal, showModal };
