
import {
  computed, onMounted, onUnmounted, ref, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/core/api';
import { piniaCart, piniaUser } from '@/pinia';
import swalAlert from '@/alert';
import { setCarousel, destroyCarousel } from '@/core/carousel/';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import itLocale from '@fullcalendar/core/locales/it';
import moment from 'moment';
import { ci } from '@fullcalendar/core/internal-common';

export default {
  components: {
    FullCalendar,
  },
  setup() {
    console.log('setup');
    const pinia = piniaCart();
    const piniaUserObject = piniaUser();
    const { dangerAlert } = swalAlert();
    const { post } = api();
    const route = useRoute();
    const router = useRouter();
    const item = {
      start: '',
      end: '',
      overlap: false,
      display: 'background',
      color: 'red',
    };
    let dateProva = [item];
    let dateProvaInt = [];
    let indexI = 0;
    const experience = ref();
    const idExperience = ref('');
    const codice = route?.params?.id?.toString();
    const code = ref('');
    const name = ref('');
    const hashtag = ref('');
    const infoGuide = ref('');
    const infoGroup = ref('');
    const infoTime = ref('');
    const infoDisability = ref('');
    const description = ref('');
    const qta = ref(0);
    const images = ref([{ name: '' }]);
    const counter = ref(0);
    const timeranges = ref([]);
    const idTimerange = ref('');
    const idDatarange = ref('');
    const tickets = ref([]);
    const date = ref();
    const availableTickets = ref(0);
    const ticketsToAdd = ref([{ id: '', qta: 0 }]);
    const errorMessageTickets = ref('');

    function less(id:string) {
      if (counter.value > 0) {
        const index = ticketsToAdd.value.findIndex((x) => x.id === id);
        if (index >= 0) {
          counter.value -= 1;
          ticketsToAdd.value[index].qta -= 1;
          if (ticketsToAdd.value[index].qta <= 0) {
            ticketsToAdd.value.splice(index, 1);
          }
        }
      }
    }

    function plus(id:string) {
      if (counter.value < availableTickets.value) {
        counter.value += 1;
        const index = ticketsToAdd.value.findIndex((x) => x.id === id);
        if (index >= 0) {
          ticketsToAdd.value[index].qta += 1;
        } else {
          const obj = {
            id,
            qta: 1,
          };
          ticketsToAdd.value.push(obj);
        }
      }
    }

    const getTicketQta = (id:string) => {
      const index = ticketsToAdd.value.findIndex((x) => x.id === id);
      if (index >= 0) {
        return ticketsToAdd.value[index].qta;
      }
      return 0;
    };

    function getSrc(index: number) {
      return process.env.VUE_APP_IMAGE_URL + images.value[index].name;
    }
    const handleDateSelect = (selectInfo: any) => {
      idDatarange.value = '';
      idTimerange.value = '';
      date.value = '';
      tickets.value = [];
      counter.value = 0;
      code.value = route?.params?.id?.toString() ?? '';
      errorMessageTickets.value = '';
      if (code.value !== '') {
        const elements = document.querySelectorAll('[data-date]');
        elements.forEach((e) => e.classList.remove('pc-selected-date'));
        const element = document.querySelectorAll(`[data-date='${selectInfo.startStr}']`);
        if (element[0]) {
          element[0].classList.add('pc-selected-date');
        }
        date.value = moment(selectInfo.startStr, 'YYYY-MM-DD');
        const data = {
          code: code.value,
          date: date.value.format('DD/MM/YYYY'),
        };
        post('getExperienceTimeranges', data)
          .then((res) => {
            timeranges.value = res.data?.timeranges ?? [];
            idDatarange.value = res.data?.idDatarange ?? '';
            if (timeranges.value.length === 0) {
              errorMessageTickets.value = 'Data non disponibile';
            }
          });
      }
    };
    console.log('code');
    console.log(codice);
    const calendarOptions = {
      locale: itLocale,
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      headerToolbar: {
        left: 'prev,next',
        center: 'title',
        right: '',
      },
      longPressDelay: 1,
      selectable: true,
      showNonCurrentDates: false,
      fixedWeekCount: false,
      selectAllow: (select: { start: any, startStr: any; }) => {
        if (moment().diff(select.start, 'days') <= 0) {
          return true;
        }
        /*
        const element = document.querySelectorAll(`[data-date='${select.startStr}']`);
        if (element[0]) {
          element[0].classList.add('fc-day-other');
        }
        */
        return false;
      },
      select: handleDateSelect,
      weekends: true,
      eventSources: [
        {
          url: process.env.VUE_APP_BASE_FREEURL.concat('?code=').concat(codice),
        },
      ],
    };

    function init() {
      destroyCarousel('#carouselpc-experience');
      counter.value = 0;
      code.value = route?.params?.id?.toString() ?? '';
      console.log('init');
      console.log(code);
      if (code.value !== '') {
        const data = {
          code: code.value,
        };
        post('getExperience', data)
          .then((res) => {
            experience.value = res?.data ?? null;
            idExperience.value = res?.data?.id ?? null;
            name.value = decodeURIComponent(res?.data?.locs[0]?.name) ?? '';
            description.value = decodeURIComponent(res?.data?.locs[0]?.description) ?? '';
            images.value = res.data?.images ?? [];
            hashtag.value = decodeURIComponent(res?.data?.locs[0]?.hashtag) ?? '';
            infoGuide.value = decodeURIComponent(res?.data?.locs[0]?.infoGuide) ?? '';
            infoGroup.value = decodeURIComponent(res?.data?.locs[0]?.infoGroup) ?? '';
            infoTime.value = decodeURIComponent(res?.data?.locs[0]?.infoTime) ?? '';
            infoDisability.value = decodeURIComponent(res?.data?.locs[0]?.infoDisability) ?? '';
            console.log(code);
            console.log(res?.data?.json.it.str_daterange.split(','));
            dateProvaInt = res?.data?.json.it.str_daterange.split(',');
            dateProva = [];
            for (indexI = 0; indexI < dateProvaInt.length; indexI += 1) {
              dateProva.push({
                start: dateProvaInt[indexI].split(' - ')[0].split('/')[2].concat('-') + dateProvaInt[indexI].split(' - ')[0].split('/')[1].concat('-') + dateProvaInt[indexI].split(' - ')[0].split('/')[0].trim(),
                end: dateProvaInt[indexI].split(' - ')[1].split('/')[2].concat('-') + dateProvaInt[indexI].split(' - ')[1].split('/')[1].concat('-') + dateProvaInt[indexI].split(' - ')[1].split('/')[0].trim(),
                overlap: false,
                display: 'background',
                color: 'red',
              });
            }
            console.log(calendarOptions);
            if (images.value.length > 0) {
              setTimeout(() => {
                setCarousel('#carouselpc-experience');
              }, 1000);
            }
          });
      }
    }
    onMounted(() => {
      init();
      console.log('onMounted');
      console.log(this);
    });

    onUnmounted(() => {
      destroyCarousel('#carouselpc-experience');
    });

    watch(route, () => {
      console.log('watch');
      init();
    });

    const isTimerangeActive = (id: string) => (idTimerange.value === id);

    const isTimerangeDisabled = (timerange: string) => {
      const time = timerange.split('-')[1].trim();
      const checkDate = moment(`${date.value.format('YYYY-MM-DD')} ${time}`, 'YYYY-MM-DD hh:mm');
      return checkDate.diff(moment(), 'seconds') <= 0;
    };

    const selectTimerange = (id: string) => {
      idTimerange.value = id;
      counter.value = 0;
      code.value = route?.params?.id?.toString() ?? '';
      ticketsToAdd.value = [];
      errorMessageTickets.value = '';
      if (code.value !== '' && date.value !== '') {
        const data = {
          code: code.value,
          date: date.value.format('DD/MM/YYYY'),
          id,
        };
        post('getExperienceTickets', data)
          .then((res) => {
            tickets.value = res.data?.tickets ?? [];
            const maxVisitorsAttr = res.data?.max_visitors ?? 0;
            const visitors = res.data?.visitors ?? 0;
            availableTickets.value = maxVisitorsAttr - visitors;
            if (tickets.value.length === 0 && availableTickets.value <= 0) {
              errorMessageTickets.value = 'Nessun biglietto disponibile';
            }
          });
      }
    };

    function addToCart(toCart = false) {
      const data = {
        tickets: ticketsToAdd.value,
        idExperience: idExperience.value,
        idTimerange: idTimerange.value,
        idDatarange: idDatarange.value,
        counter: counter.value,
        jwt: piniaUserObject.getJwt,
      };
      post('setInstanceCartExperience', data)
        .then((res) => {
          const status = res?.data?.status ?? 0;
          if (status === 1) {
            const qtaToAdd = res?.data?.qta ?? 0;
            pinia.addCounter(qtaToAdd);
            const logged = res?.data?.logged ?? false;
            if (!logged) {
              piniaUserObject.logout();
            }
            selectTimerange(idTimerange.value);
            if (toCart === true) {
              router.push({ path: '/cart' });
            }
          } else {
            dangerAlert('I biglietti non sono più disponibili per la quantità scelta')
              .then(() => {
                selectTimerange(idTimerange.value);
              });
          }
        });
    }

    function goToCart() {
      if (counter.value > 0) {
        addToCart(true);
      } else {
        router.push({ path: '/cart' });
      }
    }

    return {
      experience,
      name,
      description,
      qta,
      images,
      counter,
      less,
      plus,
      addToCart,
      goToCart,
      getSrc,
      calendarOptions,
      timeranges,
      tickets,
      isTimerangeActive,
      selectTimerange,
      getTicketQta,
      availableTickets,
      isTimerangeDisabled,
      hashtag,
      infoGuide,
      infoGroup,
      infoTime,
      infoDisability,
      errorMessageTickets,
    };
  },
};
