import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62fce828"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container py-2 py-md-5 min-vh-50" }
const _hoisted_2 = { class: "user-container-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserViewLogged = _resolveComponent("UserViewLogged")!
  const _component_UserViewLogin = _resolveComponent("UserViewLogin")!
  const _component_UserViewSignIn = _resolveComponent("UserViewSignIn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($setup.isLogged)
        ? (_openBlock(), _createBlock(_component_UserViewLogged, { key: 0 }))
        : ($setup.login)
          ? (_openBlock(), _createBlock(_component_UserViewLogin, { key: 1 }))
          : (_openBlock(), _createBlock(_component_UserViewSignIn, { key: 2 }))
    ])
  ]))
}