
import UserViewLogged from '@/components/user/UserViewLogged.vue';
import UserViewLogin from '@/components/user/UserViewLogin.vue';
import UserViewSignIn from '@/components/user/UserViewSignIn.vue';
import { computed, watch } from 'vue';
import { piniaCart, piniaUser } from '@/pinia';
import api from '@/core/api';

export default {
  components: {
    UserViewLogged,
    UserViewLogin,
    UserViewSignIn,
  },
  setup() {
    const pinia = piniaUser();
    const piniaCartObjet = piniaCart();
    const { get } = api();
    const login = computed(() => pinia.getLogin);
    const isLogged = computed(() => pinia.getIsLogged);

    function init() {
      get('getCartCount')
        .then((resCount) => {
          const timer = resCount?.data?.expiration ?? 0;
          if (timer === 0) {
            window.location.reload();
          }
        });
    }

    watch(() => piniaCartObjet.getSelectedTab, (value) => {
      if (value === 1) {
        init();
      }
    });

    return {
      isLogged,
      login,
    };
  },
};
