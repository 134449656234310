<template>
  <div class="modal fade" tabindex="-1" id="kt_modal_guide_size" data-bs-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-guide-size-container">
          <div class="modal-guide-size-container-image">
            <img src="@/assets/guideSize.png" alt="">
          </div>
          <div class="close">
            <button class="btn btn-icon" data-bs-dismiss="modal" aria-label="Close"><i class="bi bi-x-lg"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .modal-content {
    border-radius: 0 !important;
    .modal-guide-size-container {
      display: flex;
      position: relative;
      .modal-guide-size-container-image {
        width: 100%;
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
      .close {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
</style>
