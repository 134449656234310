
import CartViewProduct from '@/components/cart/sub/CartViewProduct.vue';
import CartViewExperience from '@/components/cart/sub/CartViewExperience.vue';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import api from '@/core/api';
import { piniaCart } from '@/pinia';
import swalAlert from '@/alert';

export default {
  components: {
    CartViewProduct,
    CartViewExperience,
  },
  setup() {
    const pinia = piniaCart();
    const { get, post } = api();
    const { dangerAlert, operationConfirm } = swalAlert();
    const products = ref([]);
    const experiences = ref([]);
    const total = ref(0);
    const discount = ref(0);
    const discountedTotal = ref(0);
    const discountCode = ref('');

    function next() {
      pinia.selectedTab = 1;
    }

    function init() {
      get('getCart')
        .then((res) => {
          products.value = res?.data?.data ?? [];
          total.value = res?.data?.total ?? [];
          discount.value = res?.data?.discount ?? 0;
          discountedTotal.value = res?.data?.discountedTotal ?? 0;
          experiences.value = res?.data?.experiences ?? [];
          get('getCartCount')
            .then((resCount) => {
              const count = resCount?.data?.count ?? 0;
              const timer = resCount?.data?.expiration ?? 0;
              pinia.addCounter(count);
              pinia.addTimer(timer);
            });
        });
    }

    onMounted(() => {
      init();
    });

    const isDiscounted = computed(() => discount.value === 1);

    function getDiscountCode() {
      const data = {
        code: discountCode.value,
      };
      post('getDiscountCode', data)
        .then((res) => {
          discountCode.value = '';
          const status = res?.data?.status ?? 0;
          const message = res?.data?.message ?? '';
          if (status === 1) {
            operationConfirm(message);
            init();
          } else {
            dangerAlert(message);
          }
        });
    }

    watch(() => pinia.getRemovedItem, (value) => {
      if (value === true) {
        init();
      }
      pinia.removedItem = false;
    });

    watch(() => pinia.getSelectedTab, (value) => {
      if (value === 0) {
        init();
      }
    });

    return {
      products,
      total,
      discountCode,
      isDiscounted,
      discountedTotal,
      next,
      getDiscountCode,
      experiences,
    };
  },
};
