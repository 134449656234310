<template>
  <div class="cart-product-row-one">
    <div class="cart-product-row-one-image">
      <img v-if="image" :src="image" alt="product">
      <img v-else src="@/assets/empty.png" alt="product">
    </div>
    <div class="cart-product-row-one-title">
      {{ name }}
    </div>
    <div v-if="canRemove" class="cart-product-row-one-remove">
      <button class="btn" @click="remove" @keydown="remove">Rimuovi</button>
    </div>
  </div>
  <div class="cart-product-row-two">
    <template v-if="options.length > 0">
      <div v-for="opt in options" :key="opt.name" class="cart-product-row-two-item">
        <span class="cart-product-row-two-item-title">{{ $t('common.' + opt.name) }}</span>
        <span class="cart-product-row-two-item-price">{{ opt.value }}</span>
      </div>
    </template>
    <div class="cart-product-row-two-item">
      <span class="cart-product-row-two-item-title">Q.tà</span>
      <span class="cart-product-row-two-item-price">{{ qta }}</span>
    </div>
    <div class="cart-product-row-two-item">
      <span class="cart-product-row-two-item-title">Prezzo Unitario</span>
      <span v-if="!idDiscounted" class="cart-product-row-two-item-price">{{ price }}</span>
      <span v-if="idDiscounted" class="cart-product-row-two-item-price discounted">{{ price }}</span>
      <span v-if="idDiscounted" class="cart-product-row-two-item-price">{{ discountedPrice }}</span>
    </div>
    <div class="cart-product-row-two-item">
      <span class="cart-product-row-two-item-title">Subtotale</span>
      <span class="cart-product-row-two-item-price">{{ total }}</span>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { piniaCart } from '@/pinia';
import api from '@/core/api';

export default {
  props: {
    product: Object,
    canRemove: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { post } = api();
    const pinia = piniaCart();
    const name = ref('');
    const qta = ref(0);
    const price = ref(0);
    const discountedPrice = ref(null);
    const total = ref(0);
    const image = ref('');
    const options = ref([]);

    const idDiscounted = computed(() => discountedPrice.value !== null);

    function remove() {
      const data = {
        id: props?.product?.id ?? 0,
        id_product: props?.product?.id_product ?? 0,
      };
      post('removeItemCart', data)
        .then(() => {
          pinia.removedItem = true;
        });
    }

    onMounted(() => {
      console.log(props);
      name.value = props?.product?.name ?? '';
      qta.value = props?.product?.qta ?? '';
      price.value = props?.product?.price ?? '';
      discountedPrice.value = props?.product?.discountedPrice ?? null;
      total.value = props?.product?.total ?? '';
      image.value = props?.product?.image ?? null;
      if (image.value != null) {
        image.value = process.env.VUE_APP_IMAGE_URL + image.value;
      }
      options.value = props?.product?.options ?? [];
    });

    return {
      name,
      qta,
      price,
      discountedPrice,
      total,
      image,
      options,
      idDiscounted,
      remove,
    };
  },
};
</script>
