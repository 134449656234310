
import useVuelidate from '@vuelidate/core';
import {
  computed, onMounted, reactive, ref,
} from 'vue';
import { required, sameAs } from '@vuelidate/validators';
import api from '@/core/api';
import swalAlert from '@/alert';
import { useRoute, useRouter } from 'vue-router';

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { post } = api();
    const { operationConfirm, dangerAlert } = swalAlert();
    const token = ref('');
    const state = reactive({
      password: '',
      passwordConfirm: '',
    });

    onMounted(() => {
      token.value = route?.params?.token?.toString() ?? '';
      const dataToken = {
        token: token.value,
      };
      post('existResetToken', dataToken)
        .then((resToken) => {
          const statu = resToken?.data?.status ?? 0;
          if (statu === 0) {
            router.push({ path: '/home' });
          }
        });
    });

    const rules = computed(() => ({
      password: {
        required,
        sameAsRef: sameAs(state.passwordConfirm),
      },
      passwordConfirm: {
        required,
        sameAsRef: sameAs(state.password),
      },
    }));

    const v = useVuelidate(rules, state, { $scope: false });

    function status(validation: { $error: any; }) {
      return {
        'is-invalid': validation.$error,
      };
    }

    function submit(e: { preventDefault: () => void; }) {
      e.preventDefault();
      v.value.$validate();
      if (!v.value.$invalid) {
        const dataReset = {
          token: token.value,
          password: state.password,
        };
        post('setResetToken', dataReset)
          .then((resLogin) => {
            const statu = resLogin?.data?.status ?? 0;
            if (statu === 1) {
              operationConfirm('Cambio password avvenuto con successo');
              setTimeout(() => {
                router.push({ path: '/user' });
              }, 1000);
            } else {
              dangerAlert('Errore durante il cambio della password, riprova!');
            }
          });
      }
    }

    return {
      state,
      rules,
      v,
      status,
      submit,
    };
  },
};
