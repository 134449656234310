<template>
  <div class="login-container">
    <span class="login-title">Registrati</span>
    <div class="login-subtitle">
      <span class="login-subtitle-question">Hai già un account?</span>
      <span class="login-subtitle-answer" @click="toggle" @keydown="toggle">Clicca qui</span>
    </div>
    <form @submit="submit">
      <div class="row">
        <div class="col-12 col-md-6">
          <label for="name" class="me-3"></label>
          <input type="text" id="name" name="name" class="form-control" placeholder="Nome"
                 v-model="v.name.$model" :class="status(v.name)" autocomplete="off">
        </div>
        <div class="col-12 col-md-6">
          <label for="lastname" class="me-3"></label>
          <input type="text" id="lastname" name="lastname" class="form-control" placeholder="Cognome"
                 v-model="v.lastname.$model" :class="status(v.lastname)" autocomplete="off">
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <label for="email" class="me-3"></label>
          <input type="email" id="email" name="email" class="form-control" placeholder="E-mail" aria-label="E-mail"
                 v-model="v.email.$model" :class="status(v.email)" autocomplete="off">
        </div>
        <div class="col-12 col-md-6">
          <label for="email_confirm" class="me-3"></label>
          <input type="email" id="email_confirm" name="email_confirm" class="form-control" placeholder="Conferma e-mail" aria-label="Conferma e-mail"
                 v-model="v.emailConfirm.$model" :class="status(v.emailConfirm)" autocomplete="off">
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <label for="mobile" class="me-3"></label>
          <input type="tel" id="mobile" name="mobile" class="form-control" placeholder="Cellulare" aria-label="Cellulare"
                 v-model="v.mobile.$model" :class="status(v.mobile)" autocomplete="off">
        </div>
        <div class="col-12 col-md-6">
          <label for="mobile_confirm" class="me-3"></label>
          <input type="tel" id="mobile_confirm" name="mobile_confirm" class="form-control" placeholder="Conferma cellulare" aria-label="Conferma cellulare"
                 v-model="v.mobileConfirm.$model" :class="status(v.mobileConfirm)" autocomplete="off">
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <label for="password" class="me-3"></label>
          <input type="password" id="password" name="password" class="form-control" placeholder="Password" aria-label="Password"
                 v-model="v.password.$model" :class="status(v.password)" autocomplete="off">
        </div>
        <div class="col-12 col-md-6">
          <label for="password_confirm" class="me-3"></label>
          <input type="password" id="password_confirm" name="password_confirm"
                 class="form-control" placeholder="Conferma password" aria-label="Conferma password"
                 v-model="v.passwordConfirm.$model" :class="status(v.passwordConfirm)" autocomplete="off">
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="map" class="me-3"></label>
          <vue-google-autocomplete
            ref="address"
            id="map"
            classname="form-control"
            placeholder="Inserisci l'indirizzo di spedizione"
            country="it"
            v-on:placechanged="getAddressData"
            :class="status(v.address)"
          >
          </vue-google-autocomplete>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="info" class="me-3"></label>
          <input type="text" id="info" name="info" class="form-control" placeholder="Informazioni utili per la spedizione (campanello, piano ecc.)"
                 v-model="v.info.$model" :class="status(v.info)" autocomplete="off">
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-1">
          <label for="privacy" hidden></label>
          <input type="checkbox" id="privacy" name="privacy" class="form-check-input" v-model="v.privacy.$model" :class="status(v.privacy)">
        </div>
        <div class="col-11">
          <span>Ho letto l'informativa inerente la <a href="https://www.iubenda.com/privacy-policy/19232192" target="_blank">Privacy Policy</a> e acconsento alla registrazione.</span>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-1">
          <label for="accept1" hidden></label>
          <input type="checkbox" id="accept1" name="accept1" class="form-check-input" v-model="v.accept1.$model" :class="status(v.accept1)">
        </div>
        <div class="col-11">
          <span>Acconsento al trattamento dei miei dati personali per finalità di MARKETING consistenti nell'invio, anche mediante modalità automatiche, di newsletter, comunicazioni promozionali e materiale pubblicitario.</span>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-1">
          <label for="accept2" hidden></label>
          <input type="checkbox" id="accept2" name="accept2" class="form-check-input" v-model="v.accept2.$model" :class="status(v.accept2)">
        </div>
        <div class="col-11">
          <span>Acconsente al trattamento dei propri dati personali per finalità di rilevazione dei gusti, preferenze, abitudini e/o del grado di soddisfazione, RICERCHE DI MERCATO ed altre operazioni direttamente o indirettamente riconducibili all’attività di marketing.</span>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 text-start">
          <button type="submit" class="btn btn-pc btn-pc-secondary">Conferma</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { piniaCart, piniaUser } from '@/pinia';
import { computed, reactive, ref } from 'vue';
import {
  required, email, numeric, sameAs,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import swalAlert from '@/alert';
import api from '@/core/api';
import VueGoogleAutocomplete from 'vue-google-autocomplete';

export default {
  components: {
    VueGoogleAutocomplete,
  },
  setup() {
    const pinia = piniaUser();
    const piniaCartobject = piniaCart();
    const { operationConfirm, dangerAlert } = swalAlert();
    const { post } = api();
    const jsonAddress = ref();
    const state = reactive({
      name: '',
      lastname: '',
      email: '',
      emailConfirm: '',
      mobile: '',
      mobileConfirm: '',
      password: '',
      passwordConfirm: '',
      address: '',
      info: '',
      privacy: '',
      accept1: '',
      accept2: '',
    });

    const rules = computed(() => ({
      name: {
        required,
      },
      lastname: {
        required,
      },
      email: {
        required,
        email,
        sameAsRef: sameAs(state.emailConfirm),
      },
      emailConfirm: {
        required,
        email,
        sameAsRef: sameAs(state.email),
      },
      mobile: {
        required,
        numeric,
        sameAsRef: sameAs(state.mobileConfirm),
      },
      mobileConfirm: {
        required,
        numeric,
        sameAsRef: sameAs(state.mobile),
      },
      password: {
        required,
        sameAsRef: sameAs(state.passwordConfirm),
      },
      passwordConfirm: {
        required,
        sameAsRef: sameAs(state.password),
      },
      address: {
        required,
      },
      info: {},
      privacy: {
        required,
      },
      accept1: {},
      accept2: {},
    }));

    const v = useVuelidate(rules, state, { $scope: false });

    function status(validation) {
      return {
        'is-invalid': validation.$error,
      };
    }

    function submit(e) {
      e.preventDefault();
      v.value.$validate();

      //  console.log('$error', v.value.$errors);

      if (!v.value.$invalid) {
        const data = {
          name: state.name,
          lastname: state.lastname,
          email: state.email,
          mobile: state.mobile,
          password: state.password,
          address: state.address,
          info: state.info,
          address_google: jsonAddress.value,
          privacy: (state.privacy === true) ? 1 : 0,
          accept1: (state.accept1 === true) ? 1 : 0,
          accept2: (state.accept2 === true) ? 1 : 0,
        };
        post('signin', data)
          .then((res) => {
            const statu = res?.data?.status ?? 0;
            if (statu === 1) {
              const dataLogin = {
                email: state.email,
                password: state.password,
              };
              post('userlogin', dataLogin)
                .then((resLogin) => {
                  const jwt = resLogin?.data?.jwt ?? '';
                  if (jwt !== '') {
                    pinia.setJwt(jwt);
                    operationConfirm('Registrazione avvenuta con successo')
                      .then(() => {
                        state.name = '';
                        state.lastname = '';
                        state.email = '';
                        state.emailConfirm = '';
                        state.password = '';
                        state.passwordConfirm = '';
                        state.address = '';
                        state.info = '';
                        state.privacy = '';
                        state.accept1 = '';
                        state.accept2 = '';
                        jsonAddress.value = null;
                        v.value.$reset();
                        piniaCartobject.selectedTab = 2;
                      });
                  } else {
                    dangerAlert('Errore durante la registrazione');
                  }
                });
            } else {
              const code = res?.data?.code ?? 0;
              if (code === 1) {
                dangerAlert('E-mail già in uso');
              } else {
                dangerAlert('Errore durante la registrazione');
              }
            }
          });
      }
    }

    function toggle() {
      pinia.setLogin(true);
    }

    function getAddressData(addressData, placeResultData) {
      state.address = placeResultData?.formatted_address ?? '';
      jsonAddress.value = addressData ?? null;
    }

    return {
      toggle,
      state,
      rules,
      v,
      status,
      submit,
      getAddressData,
    };
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  width: 100%;
  max-width: 500px;
  .login-title {
    display: block;
    margin-bottom: 15px;
    font-family: Poppins;
    font-size: 30px;
    font-weight: bold;
    color: #B82620;
  }
  .login-subtitle {
    display: flex;
    gap: 15px;
    .login-subtitle-question {
      font-family: Poppins;
      font-size: 18px;
      font-weight: bold;
      color: #000;
    }
    .login-subtitle-answer {
      cursor: pointer;
      text-decoration: underline;
      font-family: Poppins;
      font-size: 18px;
      font-weight: bold;
      color: #429CE2;
    }
  }
  button {
    width: 100%;
    max-width: 150px;
  }
  span {
    font-family: Poppins;
    font-size: 11px;
  }
}
</style>
