<template>
  <header class="p-3 sticky-top bg-primary">
    <div class="container">
      <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <a class="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
          <router-link to="/"><img src="@/assets/logo.svg" class="header-logo" alt="logo"></router-link>
        </a>

        <ul class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0 ms-lg-5">
          <li><router-link to="/merch" class="nav-link px-4 text-white header-nav-link">{{ $t('views.merch') }}</router-link></li>
          <li><router-link to="/experiences" class="nav-link px-4 text-white header-nav-link">{{ $t('views.experiences') }}</router-link></li>
          <!--<li><a href="https://experience.procremona.it/it/" target="_blank" class="nav-link px-4 text-white header-nav-link">{{ $t('views.experiences') }}</a></li>-->
          <li><router-link to="/contacts" class="nav-link px-4 text-white header-nav-link">{{ $t('views.contacts') }}</router-link></li>
        </ul>

        <div class="d-flex gap-4 text-end align-items-center">
          <router-link to="/user" class="nav-link text-white">
            <img src="@/assets/icon_user.svg" alt=""/>
          </router-link>
          <router-link to="/cart" class="nav-link text-white position-relative">
            <img src="@/assets/icon_cart.svg" alt=""/>
            <span v-if="counter>0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
              {{ counter }}
            </span>
          </router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { piniaCart } from '@/pinia';
import { computed } from 'vue';

export default {
  setup() {
    const pinia = piniaCart();
    const counter = computed(() => pinia.getCounter);

    return {
      counter,
    };
  },
};
</script>

<style lang="scss" scoped>
  .header-nav-link {
    font-family: Poppins;
    font-size: 15px;
    font-weight: bold;
  }
</style>
