<template>
  <div v-if="products.length>0 || experiences.length>0" class="cart-container">
    <div class="cart-container-products">
      <template v-if="products.length>0">
        <span class="merch-title">{{ $t('common.shipmentdatas') }}</span>
        <form v-if="isEditAdrress" @submit="submit">
          <div class="row align-items-end mb-5">
            <div class="col-12 col-md-6">
              <label for="address" class="me-3"></label>
              <vue-google-autocomplete
                ref="address"
                id="map"
                classname="form-control"
                placeholder="Inserisci l'indirizzo di spedizione"
                country="it"
                v-on:placechanged="getAddressData"
                :class="status(v.address)"
              >
              </vue-google-autocomplete>
              <label for="info" class="me-3"></label>
              <input type="text" id="info" name="info" class="form-control" placeholder="Informazioni utili per la spedizione (campanello, piano ecc.)"
                     v-model="v.info.$model" :class="status(v.info)" autocomplete="off">
            </div>
            <div class="col-12 col-md-6 mt-3 mt-md-0">
              <button class="btn btn-pc btn-pc-secondary me-2" @click="toggle(false)" @keydown="toggle(false)">{{ $t('common.cancel') }}</button>
              <button type="submit" class="btn btn-pc btn-pc-primary">{{ $t('common.confirm') }}</button>
            </div>
          </div>
        </form>
        <div v-else class="row align-items-end mb-5">
        <KeepAlive>
          <div class="col-12 col-md-6">
            <label for="address" class="me-3"></label>
            <span id="address" name="address" class="form-control" placeholder="Indirizzo" aria-label="Indirizzo"
                  autocomplete="off" disabled readonly>{{ getAddres }}</span>
            <template v-if="getInfo">
              <label for="info" class="me-3"></label>
              <span id="info" name="info" class="form-control" placeholder="Informazioni utili per la spedizione (campanello, piano ecc.)"
                    autocomplete="off" disabled readonly>{{ getInfo }}</span>
            </template>
          </div>
        </KeepAlive>
        <div class="col-12 col-md-6 mt-3 mt-md-0">
          <button class="btn btn-pc btn-pc-secondary" @click="toggle(true)" @keydown="toggle(true)">{{ $t('common.change') }}</button>
        </div>
      </div>
        <span class="merch-title">{{ $t('common.merch') }}</span>
        <div v-for="p in products" :key="p.id" class="cart-product">
          <CartViewProduct :product="p"></CartViewProduct>
        </div>
      </template>
      <template v-if="experiences.length>0">
        <span class="merch-title">{{ $t('common.experiences') }}</span>
        <div v-for="e in experiences" :key="e.id" class="cart-product">
          <CartViewExperience :product="e"></CartViewExperience>
        </div>
      </template>
    </div>
    <div class="cart-container-total">
      <div class="cart-container-sticky">
        <div class="cart-total summary">
          <span class="cart-total-title">Totale Carrello</span>
          <span v-if="isDiscounted" class="cart-total-price cart-subtotal-price-summary-line-through">{{ subtotal }}</span>
          <span v-else class="cart-subtotal-price-summary">{{ subtotal }}</span>
          <span v-if="isDiscounted" class="cart-subtotal-price-summary">{{ discountedTotal }}</span>
          <span class="cart-shipment-price-summary">Spese di spedizione: {{ shipment }}</span>
          <hr class="text-white">
          <span class="cart-total-price-summary">{{ total }}</span>
        </div>
        <div class="cart-continue">
          <button class="btn btn-pc btn-pc-primary" @click="pay" @keydown="pay">Conferma e paga</button>
        </div>
        <div class="cart-privacy">
          <div>
            <label for="privacy" hidden></label>
            <input type="checkbox" id="privacy" name="privacy" class="form-check-input" :class="privacyClass" v-model="privacy">
          </div>
          <div>
            <span>Ho letto i <router-link to="conditions" target="_blank">Termini e le condizion di Vendita</router-link> e voglio procedere all'acquisto.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="cart-empty-container">
    <span>Il tuo carrello è vuoto</span>
    <router-link to="/merch" class="btn btn-pc btn-pc-secondary">Continua a visitare il sito</router-link>
  </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import CartViewProduct from '@/components/cart/sub/CartViewProduct.vue';
import CartViewExperience from '@/components/cart/sub/CartViewExperience.vue';
import {
  computed, onMounted, reactive, ref, watch,
} from 'vue';
import api from '@/core/api';
import { piniaCart } from '@/pinia';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import swalAlert from '@/alert';

export default {
  components: {
    CartViewProduct,
    CartViewExperience,
    VueGoogleAutocomplete,
  },
  setup() {
    const pinia = piniaCart();
    const { dangerAlert } = swalAlert();
    const { get, post } = api();
    const products = ref([]);
    const experiences = ref([]);
    const address = ref('');
    const info = ref('');
    const jsonAddress = ref();
    const total = ref('');
    const subtotal = ref('');
    const shipment = ref('');
    const editAddress = ref(false);
    const discount = ref(0);
    const discountedTotal = ref(0);
    const privacy = ref('');
    const privacyClass = ref('');

    const state = reactive({
      address: '',
      info: '',
    });

    const rules = computed(() => ({
      address: {
        required,
      },
      info: {},
    }));

    const v = useVuelidate(rules, state, { $scope: false });

    function status(validation) {
      return {
        'is-invalid': validation.$error,
      };
    }

    function init() {
      get('getCartSummary')
        .then((res) => {
          products.value = res?.data?.data ?? [];
          total.value = res?.data?.total ?? '';
          subtotal.value = res?.data?.subtotal ?? '';
          shipment.value = res?.data?.shipment ?? '';
          address.value = res?.data?.address ?? '';
          info.value = res?.data?.info ?? '';
          discount.value = res?.data?.discount ?? 0;
          discountedTotal.value = res?.data?.discountedTotal ?? 0;
          experiences.value = res?.data?.experiences ?? [];
        });
    }

    const isDiscounted = computed(() => discount.value === 1);

    onMounted(() => {
      init();
    });

    watch(() => pinia.getSelectedTab, (value) => {
      if (value === 2) {
        init();
      }
    });

    function pay() {
      if (privacy.value !== true) {
        privacyClass.value = 'is-invalid';
        return;
      }
      privacyClass.value = '';
      post('pay')
        .then((res) => {
          const statu = res?.data?.status ?? 0;
          if (statu === 1) {
            const url = res?.data?.url ?? '';
            window.location = url;
          } else {
            dangerAlert('Il carrello è scaduto')
              .then(() => {
                window.location.reload();
              });
          }
        });
    }

    function toggle(value) {
      if (value === false) {
        state.address = '';
        state.info = '';
        jsonAddress.value = null;
      }
      editAddress.value = value;
    }

    function getAddressData(addressData, placeResultData) {
      state.address = placeResultData?.formatted_address ?? '';
      jsonAddress.value = addressData ?? null;
    }

    function submit(e) {
      e.preventDefault();
      v.value.$validate();
      if (!v.value.$invalid) {
        const data = {
          address: state.address,
          address_google: jsonAddress.value,
          info: state.info,
        };
        post('getCartSummary', data)
          .then((res) => {
            products.value = res?.data?.data ?? [];
            total.value = res?.data?.total ?? '';
            subtotal.value = res?.data?.subtotal ?? '';
            shipment.value = res?.data?.shipment ?? '';
            address.value = res?.data?.address ?? '';
            info.value = res?.data?.info ?? '';
            editAddress.value = false;
            state.address = '';
            state.info = '';
            jsonAddress.value = null;
          });
      }
    }

    const isEditAdrress = computed(() => editAddress.value);
    const getAddres = computed(() => address.value);
    const getInfo = computed(() => info.value);

    return {
      products,
      total,
      subtotal,
      shipment,
      getAddres,
      getInfo,
      isEditAdrress,
      state,
      rules,
      v,
      isDiscounted,
      discountedTotal,
      privacy,
      privacyClass,
      status,
      pay,
      toggle,
      getAddressData,
      submit,
      experiences,
    };
  },
};
</script>
<style lang="scss">

.cart-container {
  .cart-container-total {
    .cart-container-sticky {
      .cart-total {
        .cart-total-title {
          font-family: Poppins;
          font-size: 20px;
          color: #FFF;
          margin-bottom: 15px;
        }
        .cart-total-price-summary {
          font-family: Poppins;
          font-size: 30px;
          font-weight: bold;
          color: #FFF;
        }
        .cart-subtotal-price-summary {
          font-family: Poppins;
          font-size: 20px;
          font-weight: bold;
          color: #FFF;
        }
        .cart-subtotal-price-summary-line-through {
          text-decoration: line-through;
          font-size: 15px;
        }
        .cart-shipment-price-summary {
          font-family: Poppins;
          font-size: 15px;
          font-weight: bold;
          color: #FFF;
        }
      }
      .cart-privacy {
        display: flex;
        gap: 15px;
        margin-top: 15px;
        span {
          font-family: Poppins;
          font-size: 11px;
        }
      }
    }
  }
}
</style>
