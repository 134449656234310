<template>
  <div class="login-container">
    <span class="login-title">Profilo</span>
    <div class="row">
      <div class="col-12 col-md-6">
        <label for="name" class="me-3"></label>
        <input type="text" id="name" name="name" class="form-control" placeholder="Nome" aria-label="Nome"
               v-model="name" autocomplete="off" disabled readonly>
      </div>
      <div class="col-12 col-md-6">
        <label for="lastname" class="me-3"></label>
        <input type="text" id="lastname" name="lastname" class="form-control" placeholder="Cognome" aria-label="Cognome"
               v-model="lastname" autocomplete="off" disabled readonly>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <label for="email" class="me-3"></label>
        <input type="email" id="email" name="email" class="form-control" placeholder="E-mail" aria-label="E-mail"
               v-model="email" autocomplete="off" disabled readonly>
      </div>
      <div class="col-12 col-md-6">
        <label for="mobile" class="me-3"></label>
        <input type="text" id="mobile" name="mobile" class="form-control" placeholder="Mobile" aria-label="Mobile"
               v-model="mobile" autocomplete="off" disabled readonly>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label for="address" class="me-3"></label>
        <input type="text" id="address" name="address" class="form-control" placeholder="Indirizzo" aria-label="Indirizzo"
               v-model="address" autocomplete="off" disabled readonly>
      </div>
    </div>
    <div v-if="info" class="row">
      <div class="col-12">
        <label for="info" class="me-3"></label>
        <input type="text" id="info" name="info" class="form-control" placeholder="Informazioni utili per la spedizione (campanello, piano ecc.)" aria-label="Informazioni utili per la spedizione (campanello, piano ecc.)"
               v-model="info" autocomplete="off" disabled readonly>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12 text-end text-md-start">
        <button v-if="!fromCart" class="btn btn-pc btn-pc-secondary" @click="logout" @keydown="logout">Esci</button>
        <button v-else class="btn btn-pc btn-pc-primary" @click="next" @keydown="next">Continua</button>
      </div>
    </div>
  </div>
</template>

<script>
import { piniaUser, piniaCart } from '@/pinia';
import { onMounted, ref, watch } from 'vue';
import api from '@/core/api';
import swalAlert from '@/alert';

export default {
  props: {
    fromCart: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const pinia = piniaUser();
    const piniaCartobject = piniaCart();
    const { post } = api();
    const { operationConfirm, dangerAlert } = swalAlert();
    const name = ref('');
    const lastname = ref('');
    const email = ref('');
    const mobile = ref('');
    const address = ref('');
    const info = ref('');

    function logout() {
      post('logout')
        .then(() => {
          pinia.logout();
        });
    }

    function getInfo() {
      const data = {
        jwt: pinia.getJwt,
      };
      post('info', data)
        .then((res) => {
          const statu = res?.data?.status ?? 0;
          if (statu === 1) {
            name.value = res?.data?.data?.name ?? '';
            lastname.value = res?.data?.data?.lastname ?? '';
            email.value = res?.data?.data?.email ?? '';
            mobile.value = res?.data?.data?.mobile ?? '';
            address.value = res?.data?.data?.address ?? '';
            info.value = res?.data?.data?.info ?? '';
          } else {
            alert('Error');
          }
        });
    }

    function next() {
      piniaCartobject.selectedTab = 2;
    }

    onMounted(() => {
      getInfo();
    });

    watch(() => pinia.getJwt, () => {
      getInfo();
    }, { deep: true });

    return {
      name,
      lastname,
      email,
      mobile,
      address,
      info,
      logout,
      next,
    };
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  .login-title {
    display: block;
    margin-bottom: 15px;
    font-family: Poppins;
    font-size: 30px;
    font-weight: bold;
    color: #B82620;
  }
  button {
    width: 100%;
    max-width: 150px;
  }
}
</style>
